<template>
  <div class="vipps-checkout-form">
    <v-card v-if="!isLoading && !error && formData" flat>
      <v-card-title>Complete Your Payment with Vipps</v-card-title>
      <v-card-text>
        <v-btn
          color="primary"
          block
          large
          class="mt-4"
          @click="redirectToVipps"
          :loading="isRedirecting"
        >
          Pay {{ amount.toString() }} {{ currency }} with Vipps
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card v-else-if="error" flat color="error" dark>
      <v-card-text>
        {{ error }}
        <v-btn text @click="initializePayment">Retry</v-btn>
      </v-card-text>
    </v-card>

    <v-card v-else flat>
      <v-card-text class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <div class="mt-2">Initializing payment...</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

interface VippsFormData {
  redirectUrl: string;
  orderId: string;
}

@Component
export default class VippsCheckoutForm extends Vue {
  @Prop({ required: true }) readonly amount!: number;
  @Prop({ required: true }) readonly description!: string;
  @Prop({ required: true }) readonly customerInfo!: any;
  @Prop({ required: true }) readonly currency!: string;

  @Action("payment/initiatePayment") initiatePayment!: Function;

  isLoading = false;
  isRedirecting = false;
  error: string | null = null;
  formData: VippsFormData | null = null;

  async mounted() {
    await this.initializePayment();
  }

  async initializePayment() {
    this.isLoading = true;
    this.error = null;

    try {
      const response = await this.initiatePayment({
        amount: this.amount,
        description: this.description,
        customerEmail: this.customerInfo.email,
        customerFirstName: this.customerInfo.firstName,
        customerLastName: this.customerInfo.lastName,
        customerPhone: this.customerInfo.phone,
        successUrl: `${window.location.origin}/payment/success`,
        failureUrl: `${window.location.origin}/payment/failure`,
        provider: "vipps",
      });

      this.formData = response;
    } catch (err) {
      this.error = "Failed to initialize payment. Please try again.";
      console.error("Payment initialization error:", err);
    } finally {
      this.isLoading = false;
    }
  }

  async redirectToVipps() {
    if (!this.formData?.redirectUrl) {
      this.error = "No redirect URL available";
      return;
    }

    this.isRedirecting = true;
    try {
      window.location.href = this.formData.redirectUrl;
    } catch (err) {
      this.error = "Failed to redirect to Vipps. Please try again.";
      console.error("Vipps redirect error:", err);
      this.isRedirecting = false;
    }
  }
}
</script>

<style scoped>
.vipps-checkout-form {
  max-width: 500px;
  margin: 0 auto;
}
</style>
