<template>
  <VippsCheckoutForm
    :amount="amount"
    :description="description"
    :customer-info="customerInfo"
    :currency="currency"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { IPaymentStrategy } from "./IPaymentStrategy";
import VippsCheckoutForm from "../VippsCheckoutForm.vue";

@Component({
  components: {
    VippsCheckoutForm,
  },
})
export default class VippsStrategy extends Vue implements IPaymentStrategy {
  @Prop({ required: true }) readonly amount!: number;
  @Prop({ required: true }) readonly description!: string;
  @Prop({ required: true }) readonly customerInfo!: any;
  @Prop({ required: true }) readonly currency!: string;

  async initiatePayment(
    amount: number,
    description: string,
    customerInfo: any
  ): Promise<void> {
    // The actual payment initiation is handled by the VippsCheckoutForm component
  }
}
</script>
