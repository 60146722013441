import axios from 'axios';
import {ActionTree, GetterTree, MutationTree} from 'vuex';
import {APIURL2} from "@/main";
import {mutationStringWorkingHours} from "@/store/workingHours";

export interface userState {
    bookingPages: IBookingPage[]
}

// Interface Model
export interface IBookingPage {
    name: string,
    id: string,
}


export const state: userState = {
    bookingPages: [],
};

export const getters: GetterTree<userState, any> = {
    bookingPages: state => state.bookingPages,
};

export const mutations: MutationTree<userState> = {
    setBookingPages(state, payload: IBookingPage[]) {
        state.bookingPages = payload;
    },
};


// @ts-ignore
export const actions: ActionTree<userState, any> = {

    async postAuditLog({commit}, payload): Promise<any> {

        return new Promise((resolve, reject) => {
            axios.post(APIURL2 + 'AuditLog/asAnonymous/' + payload.userId, payload.audit, {}).then((res) => {
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
// @ts-ignore
    async getBookingPages({dispatch, commit}, payload): Promise<IBookingPage[]> {
        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'Company/connect?id=' + payload).then((res) => {
                commit("setBookingPages", res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
};

export const bookingPageSelector = {
    actions,
    mutations,
    getters,
    state,
};
