<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Dialog from "@/components/global/Dialog.vue";

//* Component definition ************************************************************

@Component({
  name: "DialogQueueDisplay",
  components: { Dialog },
})

//* Class ***************************************************************************
export default class DialogQueueDisplay extends Vue {
  get getDialogQueue() {
    return this.$store.getters.dialogQueue;
  }
}
</script>

<template>
  <div>
    <v-dialog
      v-for="(dialog, index) in getDialogQueue"
      :key="index"
      v-model="dialog.enabled"
      persistent
      max-width="290"
    >
      <Dialog :dialog="dialog"></Dialog>
    </v-dialog>
  </div>
</template>

<style scoped></style>
