import Vue from "vue";
import Vuex from "vuex";
import { user } from "./user";
import { customerAccounts } from "./customerAccounts";
import { dialog } from "./dialog";
import { snackbarModule } from "./snackbar";
import { appointments } from "./appointments/appointments";
import { appointmentTypes } from "./appointments/appointmentTypes";
import { workingHours } from "./workingHours";
import { bookingPageSelector } from "./bookingPageSelector";
import { bookingHourConnectionModule } from "@/store/bookingHourConnection";
import { staff } from "./staff";
import { payment } from "./payment/index";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    user,
    customerAccounts,
    dialog,
    snackbarModule,
    workingHours,
    appointments,
    appointmentTypes,
    bookingPageSelector,
    bookingHourConnectionModule,
    staff,
    payment,
  },
});
