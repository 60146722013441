import { getRequest, postRequest, putRequest } from "@/Utilities/axiosHelper";
import { ICustomerAccount } from "@/store/customerAccounts";
import {
  ICustomerAccountAnonymousRequest,
  ICustomerAccountDTO,
  ICustomerAccountRequest,
  IEditCustomerAccountDTO,
  IPostCustomerAccountDTO,
  IResetPasswordByCode,
} from "@shared/types";

export function getAppointmentByCancelToken(cancelId: string): Promise<any> {
  return getRequest("Appointment/cancelToken?id=" + cancelId);
}

export function cancelAppointmentByCustomerAccount(
  appointmentId: number,
  customerToken: string,
  phoneNumber: string,
  userId: string
): Promise<any> {
  return postRequest(
    "Appointment/cancelByCustomer?appointmentId=" +
      appointmentId +
      "&customerToken=" +
      customerToken +
      "&phoneNumber=" +
      phoneNumber +
      "&userId=" +
      userId,
    {}
  );
}

export async function postCustomerAccount(customerAccount: IPostCustomerAccountDTO): Promise<any> {
  let customer = await postRequest("CustomerAccount", customerAccount);
  return new Promise((resolve, reject) => {
    resolve(customer);
  });
}

export async function getCustomerAccountByPhoneNumber(
  req: ICustomerAccountAnonymousRequest
): Promise<ICustomerAccount> {
  let customer = await postRequest("CustomerAccount/byPhone", req);
  return new Promise((resolve, reject) => {
    resolve(customer);
  });
}

export async function getCustomerAccountByPhoneNumberAndPassword(
  payload: ICustomerAccountRequest
): Promise<ICustomerAccount> {
  return new Promise(async (resolve, reject) => {
    try {
      let customer = await postRequest("CustomerAccount/byPhoneAndPassword", payload, true);
      resolve(customer);
    } catch (e) {
      reject(e);
    }
  });
}

export async function putCustomerAccount(payload: IEditCustomerAccountDTO): Promise<ICustomerAccount> {
  return new Promise(async (resolve, reject) => {
    try {
      let customer = await putRequest("CustomerAccount", payload);
      resolve(customer);
    } catch (e) {
      reject(e);
    }
  });
}

export async function sendNewPassordToCustomerAccount(
  payload: ICustomerAccountAnonymousRequest
): Promise<ICustomerAccount> {
  return new Promise(async (resolve, reject) => {
    try {
      let customer = await postRequest("CustomerAccount/resetPassword", payload);
      resolve(customer);
    } catch (e) {
      reject(e);
    }
  });
}
export async function sendPasswordCode(payload: IResetPasswordByCode): Promise<ICustomerAccountDTO> {
  return new Promise(async (resolve, reject) => {
    try {
      let customer = await postRequest("CustomerAccount/resetPasswordByCode", payload);
      resolve(customer);
    } catch (e) {
      reject(e);
    }
  });
}
