import { render, staticRenderFns } from "./receipt-page.vue?vue&type=template&id=2e30b34d&scoped=true"
import script from "./receipt-page.vue?vue&type=script&lang=ts"
export * from "./receipt-page.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e30b34d",
  null
  
)

export default component.exports