<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card>
      <v-card-title>{{ $t($ts.payment.title) }}</v-card-title>
      <v-card-text>
        <component
          :is="paymentStrategy"
          v-if="paymentStrategy"
          :amount="amount"
          :description="description"
          :customer-info="customerInfo"
          :currency="userToBook?.currency || 'USD'"
        />
        <v-alert v-else type="warning" text>
          {{ $t($ts.payment.noProviderConfigured) }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { IUserDTO } from "@shared/types";
import PayUStrategy from "./strategies/PayUStrategy.vue";
import VippsStrategy from "./strategies/VippsStrategy.vue";
import { OnlineTransactionProvider } from "@shared/types";

@Component({
  components: {
    PayUStrategy,
    VippsStrategy,
  },
})
export default class PaymentDialog extends Vue {
  @Prop({ required: true }) readonly dialog!: boolean;
  @Prop({ required: true }) readonly amount!: number;
  @Prop({ required: true }) readonly description!: string;
  @Prop({ required: true }) readonly customerInfo!: any;

  @Getter public userToBook!: IUserDTO;

  OnlineTransactionProvider = OnlineTransactionProvider;

  get paymentStrategy(): string | null {
    if (!this.userToBook) {
      return null;
    }

    switch (this.userToBook.onlineTransactionProvider) {
      case OnlineTransactionProvider.PayU:
        return "PayUStrategy";
      case OnlineTransactionProvider.Vipps:
        return "VippsStrategy";
      case OnlineTransactionProvider.Stripe:
      case OnlineTransactionProvider.None:
      default:
        return null;
    }
  }
}
</script>
