var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{},[_c('v-row',{attrs:{"justify":"center","cols":"auto"}},[_c('v-col',{attrs:{"lg":"6","xs":"12","sm":"8"}},[(!_vm.userToBook.useCustomerAccounts)?_c('v-card',{staticClass:"choose-service-card pa-6",staticStyle:{"max-width":"499px","margin":"auto"},attrs:{"flat":""}},[_c('v-card-text',[_c('customer-account-form',{attrs:{"data-cy":"nextButton","showPhone":_vm.userToBook.customerRequiredPhoneNumber,"showPassword":false,"buttonText":_vm.$t('customerAccount.next')},on:{"submit":_vm.saveCustomerAccountToState}})],1)],1):_vm._e(),(_vm.userToBook.useCustomerAccounts)?_c('v-card',{attrs:{"flat":""}},[(
            _vm.hasSelectedNewUser === false && _vm.hasSelectedExistingUser === false
          )?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.hasSelectedExistingUser)?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t(_vm.$ts.customerAccount.enterPassword))+" ")]):_vm._e(),(_vm.hasSelectedExistingUser)?_c('v-card-title',[_c('v-form',[_c('v-text-field',{staticClass:"ml-1",attrs:{"label":_vm.$t('customerAccount.password'),"required":"","outlined":"","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1):_vm._e(),(
            _vm.hasSelectedNewUser === false && _vm.hasSelectedExistingUser === false
          )?_c('v-card-title',[_c('v-form',{ref:"phoneForm",attrs:{"lazy-validation":""},model:{value:(_vm.phoneValid),callback:function ($$v) {_vm.phoneValid=$$v},expression:"phoneValid"}},[_c('v-text-field',{staticClass:"ml-1",attrs:{"rules":_vm.phoneRules,"label":_vm.$t('customerAccount.phoneNumber'),"required":"","outlined":""},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),(_vm.mustLogin)?_c('v-text-field',{staticClass:"ml-1",attrs:{"label":_vm.$t('customerAccount.password'),"required":"","outlined":"","type":"password"},model:{value:(_vm.phoneNumberPassword),callback:function ($$v) {_vm.phoneNumberPassword=$$v},expression:"phoneNumberPassword"}}):_vm._e()],1)],1):_vm._e(),(
            _vm.hasSelectedNewUser === false && _vm.hasSelectedExistingUser === true
          )?_c('v-card-title',[_c('v-btn',{attrs:{"dark":"","color":"#5C6BC0"},on:{"click":_vm.getCustomerAccountInfo}},[_vm._v(_vm._s(_vm.$t(_vm.$ts.customerAccount.login)))]),_c('br'),(_vm.showResetPasswordField === false)?_c('v-btn',{staticStyle:{"margin-top":"10px"},on:{"click":_vm.customAccountPassword}},[_vm._v(_vm._s(_vm.$t(_vm.$ts.customerAccount.forgotPassword)))]):_vm._e()],1):_vm._e(),(
            _vm.hasSelectedNewUser === false && _vm.hasSelectedExistingUser === false
          )?_c('v-card-title',[_c('v-btn',{attrs:{"data-cy":"nextButton","dark":"","color":"#5C6BC0"},on:{"click":_vm.attemptLoginCustomerAccount}},[_vm._v(_vm._s(_vm.loginButton))]),_c('br'),(_vm.showResetPasswordField === false && _vm.mustLogin)?_c('v-btn',{on:{"click":_vm.customAccountPassword}},[_vm._v(_vm._s(_vm.$t(_vm.$ts.customerAccount.forgotPassword)))]):_vm._e()],1):_vm._e(),(
            (_vm.hasSelectedNewUser === false &&
              _vm.hasSelectedExistingUser === true) ||
            _vm.mustLogin
          )?_c('v-card-title',[(_vm.showResetPasswordField)?_c('div',[_c('v-form',{ref:"formReset",attrs:{"lazy-validation":""}},[_c('v-text-field',{staticClass:"ml-1",attrs:{"label":_vm.$t('customerAccount.enterCode'),"rules":_vm.codeRules,"required":"","outlined":""},model:{value:(_vm.passwordResetCode),callback:function ($$v) {_vm.passwordResetCode=$$v},expression:"passwordResetCode"}}),_c('v-text-field',{staticClass:"ml-1",attrs:{"rules":_vm.passwordRules,"label":_vm.$t('customerAccount.newPassword'),"required":"","outlined":"","type":"password"},model:{value:(_vm.resetNewPassword),callback:function ($$v) {_vm.resetNewPassword=$$v},expression:"resetNewPassword"}})],1),_c('v-btn',{on:{"click":_vm.attemptChangePassword}},[_vm._v("Ok")])],1):_vm._e()]):_vm._e(),(_vm.hasSelectedNewUser)?_c('v-card-title',[_vm._v(_vm._s(_vm.$t(_vm.$ts.customerAccount.createUser)))]):_vm._e(),(_vm.hasSelectedNewUser)?_c('div',[_c('v-card-text',[_c('customer-account-form',{attrs:{"showPhone":!_vm.hasSelectedNewUser,"showPassword":_vm.hasSelectedNewUser,"buttonText":_vm.$t('customerAccount.createProfile')},on:{"submit":_vm.createCustomerAccount}})],1)],1):_vm._e()],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }