import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import CustomerAccount from "@/components/user/CustomerAccount.vue";
import BookingPageSelector from "@/views/user/BookingPageSelector.vue";
import BookingStepper from "@/components/user/BookingStepper.vue";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/velg/:company",
    name: "choose-page",
    component: BookingPageSelector,
  },
  {
    path: "/:company",
    name: "booking-page",
    component: BookingStepper,
  },
  {
    path: "/:company/min-side",
    name: "customer-account",
    component: CustomerAccount,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
