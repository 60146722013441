//* Script
***************************************************************************
//************************************************************************************

<script lang="ts">
"use strict";

import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Loader from "@/components/global/Loader.vue";
import "animate.css";

//* Component definition ************************************************************

@Component({
  name: "hour_list",
  components: { Loader },
})

//* Class ***************************************************************************
export default class HourList extends Vue {
  @Prop({}) hours;
  @Prop({}) firstAvailableDay;
  @Prop({}) isLoading;
  @Prop({}) label;
  @Prop({ default: null }) selectedHour;

  selectHourByClick(hour, i) {
    this.$emit("select", { hour: hour, i: i });
  }

  get availableHours() {
    return this.hours.length > 0;
  }

  gotoFirstAvailableDay() {
    this.$emit("goto");
  }

  mounted() {}
}
</script>

<template>
  <v-card min-height="287" flat color="white">
    <v-col class="pa-3" cols="auto" justify="center">
      <v-row justify="center">
        <span
          class="text-center"
          style="color: rgba(0, 0, 0, 0.87); font-weight: bold"
          >{{ label }}</span
        >
      </v-row>
      <v-row justify="center">
        <div v-if="isLoading" class="loader-wrapper">
          <v-col
            cols="auto"
            justify="center"
            align-self="center"
            justify-content="center"
          >
            <v-btn
              disabled
              color="#D9E4F1"
              class="list-item mx-1 my-1"
              :key="hour.label"
              v-for="(hour, i) in 15"
            >
              <v-progress-circular indeterminate></v-progress-circular>
            </v-btn>
          </v-col>
        </div>

        <v-col>
          <div v-if="!availableHours && !isLoading">
            <p>{{ $t($ts.hourList.noAvailableHours) }}</p>
            <v-btn
              v-if="firstAvailableDay != null"
              @click="gotoFirstAvailableDay"
              >{{ $t($ts.hourList.findFirstAvailableHour) }}</v-btn
            >
          </div>
          <div v-if="!isLoading">
            <transition-group name="list">
              <v-btn
                @click="selectHourByClick(hour, i)"
                width="80"
                color="#D9E4F1"
                class="list-item mx-1 my-1"
                :key="hour.label"
                v-for="(hour, i) in hours"
                >{{ hour.label }}</v-btn
              >
            </transition-group>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>

//* Css
******************************************************************************
//************************************************************************************

<style lang="scss" scoped>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}

.loader-wrapper {
  width: 100%;
}

.hours-wrapper {
  width: 100%;
  background: white;
  height: auto;
  min-height: 200px;
  .hour-headline {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    color: black;
  }

  .hour {
    text-align: center;
    border-radius: 3px;
    max-width: 100%;
    background: #f0efef;
    color: #333;
    font-weight: normal;
    width: auto;
    height: auto;
    padding: 6px 22.6px;
    margin: 5px 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 95%;
    cursor: pointer;
    display: inline-block;

    width: 60px;
    height: 40px;
    font-size: 95%;
    padding: 0;
    padding-top: 10px;
    background: #d9e4f1;

    &:hover {
      background: #b0cbeb;
    }
  }
}
</style>
