<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import HelloWorld from "./components/HelloWorld.vue";
import { SnackbarObject } from "@/store/snackbar";
import DialogQueueDisplay from "@/components/global/DialogQueueDisplay.vue";

import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Watch } from "vue-property-decorator";
import { IUser } from "@/store/user";
import Loader from "@/components/global/Loader.vue";
import { ICustomerAccount } from "@/store/customerAccounts";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import { DialogType, IDialog } from "@/store/dialog";

@Component({
  name: "MainApp",
  components: { CustomButton, Loader, DialogQueueDisplay },
})
export default class MainApp extends Vue {
  isShowingSnackbar: boolean = false;
  snackbarDuration: number = 4000;
  snackbarTimeout: number | undefined;

  public isLoggedIn: boolean = false;
  public showMobileMenu: boolean = false;
  public showFooter: boolean = true;

  @Getter public snackbar: SnackbarObject;
  @Action public postSnackbarMessage: Function | undefined;
  @Action public getCurrentUser: Function | undefined;
  @Getter public user: IUser | undefined;
  @Getter public hasResolvedUser: boolean | undefined;
  @Action public getUserAsAnonymous: Function;
  @Getter public userToBook;
  @Getter public customerAccount;
  @Action logoutCustomerAccount;
  @Action addToDialogQueue;
  @Mutation setBookingStep;
  @Action public getCustomFieldsAsAnonymous: Function;

  public choosePage = false;

  @Watch("showMobileMenu")
  onChangeMobileMenu(dsa, old) {
    if (this.showMobileMenu) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }

  @Watch("$route")
  onChangeRoute(newRoute, oldroute) {
    if (newRoute.name == "booking-page") {
      this.isLoggedIn = false;
    }
    if (newRoute.name == "customer-account") {
      this.isLoggedIn = true;
    }
  }

  @Watch("userToBook")
  onChangeUser(newUser, oldUser) {
    if (newUser != null) {
      //@ts-ignore
      if (
        newUser.language != null &&
        newUser.language != "" &&
        newUser.language != localStorage.getItem("locale")
      ) {
        this.$i18n.locale = newUser.language;
        localStorage.setItem("locale", newUser.language);
      }
    }
  }

  logoutAction() {
    if (this.customerAccount != null) {
      let vm = this;

      vm.isLoggedIn = false;
      vm.$router.push("/" + vm.userToBook.bookingLink);
      vm.logoutCustomerAccount();
    } else {
      this.showMobileMenu = false;
      this.$router.push("/" + this.userToBook.bookingLink + "/min-side/");
    }
  }

  created() {
    let extraOptions = this.$route.params.extraoptions;
    if (extraOptions == "iframe") {
      this.showFooter = false;
    }
    let newRoute = this.$router.currentRoute;

    if (newRoute.name == "booking-page") {
      this.isLoggedIn = false;
    }
    if (newRoute.name == "customer-account") {
      this.isLoggedIn = true;
    }

    if (newRoute.name == "choose-page") {
      this.choosePage = true;
    }
    //this.getCustomFieldsAsAnonymous(this.$route.params.company);
    this.getUserAsAnonymous(this.$route.params.company);
  }

  get loginText() {
    if (this.customerAccount == null) {
      return this.$t(this.$ts.mainApp.myPage).toString();
    } else {
      return this.$t(this.$ts.mainApp.logout).toString();
    }
  }

  goBack() {
    this.$store.commit("goBackBookingStep", 1);
  }

  enterCustomerSettings() {
    this.showMobileMenu = false;
    let vm = this;
    if (this.bookingStep > 1 && this.bookingStep != 5) {
      let dialog: IDialog = {
        text: this.$t(this.$ts.mainApp.leaveBooking).toString(),
        type: DialogType.Choice,
        action: function () {
          vm.$router.push("/" + vm.userToBook.bookingLink + "/min-side");
        },
      };
      this.addToDialogQueue(dialog);
    } else {
      vm.$router.push("/" + vm.userToBook.bookingLink + "/min-side");
    }
  }
  enterBooking() {
    this.showMobileMenu = false;
    if (this.$router.currentRoute.name == "booking-page") {
      return;
    }
    this.setBookingStep(1);
    this.$router.push("/" + this.userToBook.bookingLink);
  }

  get isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  get stepperTitle() {
    return this.$store.getters.stepperTitle;
  }

  @Watch("bookingStep")
  onBookingStepChange() {
    window.scrollTo({
      top: 1,
      left: 0,
      //@ts-ignore
      behavior: "instant",
    });
  }

  get bookingStep() {
    return this.$store.getters.bookingStep;
  }
}
</script>

<template>
  <div>
    <div v-if="!choosePage">
      <v-navigation-drawer
        v-model="showMobileMenu"
        app
        clipped
        class="hidden-md-and-up"
        temporary
      >
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ loginText }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app>
        <v-main>
          <v-container fluid>
            <router-view></router-view>
          </v-container>
        </v-main>
        <v-footer app>
          <v-col class="text-center" cols="12">
            <span class="white--text">© {{ new Date().getFullYear() }}</span>
          </v-col>
        </v-footer>
      </v-app>
    </div>

    <div v-if="choosePage">
      <router-view v-if="userToBook != null"></router-view>
    </div>

    <DialogQueueDisplay />
  </div>
</template>

<style scoped lang="scss">
.active {
  background: #ebebeb;
}
#app
  > div
  > div:nth-child(2)
  > nav
  > div.v-navigation-drawer__content
  > div.v-list.v-sheet.v-sheet--tile.theme--dark.v-list--dense
  > div
  > div.v-list-group__header.v-list-item.v-list-item--active.v-list-item--link.theme--dark
  > div.v-list-item__icon.v-list-group__header__append-icon {
  color: white !important;
}

.main-content {
  max-width: 1540px;
  margin: auto;
  height: auto;
  padding: 40px 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.v-content {
  @media only screen and (max-width: 600px) {
    padding: 0 !important;
  }
}

.pa-xs-0 {
  @media only screen and (max-width: 600px) {
    padding: 0 !important;
  }
}
.header-logout {
  margin-left: -2rem;
  padding: 12px 20px !important;

  background-color: #fff !important;
  border: 2px solid #5c6bc0;
  border-radius: 5rem;
  color: #5c6bc0 !important;
  cursor: pointer;
  &:hover {
    background: #cccccc !important;
  }
  &--mobile {
    margin-left: 0;
  }
}

.my-page-button {
  margin-right: 20px;
}
.header-name {
  border-radius: 20px;
  background-color: #f1f1f1;
  color: #062140;
  max-width: 14.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 16px;
  padding-right: 48px;
}
body {
  font-size: 14px;
}
html {
  scroll-behavior: smooth;
}
p {
  margin-bottom: 0;
}
.hasInstallMessage {
  margin-top: 50px;
  display: inline-block;
}
.loader-wrapper-container {
  padding: 50px;
}

.contact-detail a {
  color: white;
}
.contact-detail {
  color: white !important;
  font-size: 16px !important;
}

/*.application {*/
/*font-family: inherit;*/
/*line-height: inherit;*/
/*}*/

.Chart {
  background: #212733;
  border-radius: 15px;
  box-shadow: 0px 2px 15px rgba(25, 25, 25, 0.27);
  margin: 25px 0;
}

.Chart h2 {
  margin-top: 0;
  padding: 15px 0;
  color: rgba(255, 0, 0, 0.5);
  border-bottom: 1px solid #323d54;
}
</style>
