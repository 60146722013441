export enum HttpVerb {
	Get = 0,
	Post = 1,
	Put = 2,
	Delete = 3,
	Patch = 4,
	Head = 5,
	Options = 6,
	Trace = 7
}
export interface ICompanyDTO
{
	id: number;
}
export interface ICustomerStatDTO
{
	bookings: number;
	customerName: string;
	totalMoneySpent: number;
}
export interface INewCustomerStatsDTO
{
	monthlyNewCustomers: number[];
	monthlyTotalCustomers: number[];
}
export interface IServiceStatDTO
{
	bookings: number;
	color: string;
	serviceName: string;
}
export interface ITypeFormField
{
	description: string;
	name: string;
}
export interface AppointmentTypeFormDTO
{
	autoApply: ITypeFormField;
	color: ITypeFormField;
	description: ITypeFormField;
	disablePrice: ITypeFormField;
	durationInMinutes: ITypeFormField;
	hideInBooking: ITypeFormField;
	isExtraService: ITypeFormField;
	mustPayOnline: ITypeFormField;
	payOnlinePrice: ITypeFormField;
	price: ITypeFormField;
	showCustomColor: ITypeFormField;
	title: ITypeFormField;
	unavailableForOnlineBooking: ITypeFormField;
}
export interface ServiceCategoryFormDTO
{
	title: ITypeFormField;
}
export interface AccountRoleFormDTO
{
	administration: ITypeFormField;
	appointmentsAndClients: ITypeFormField;
	name: ITypeFormField;
	settings: ITypeFormField;
	statisticsAndFinances: ITypeFormField;
}
export interface StaffMemberFormDTO
{
	emailAddress: ITypeFormField;
	firstName: ITypeFormField;
	lastName: ITypeFormField;
	phoneNumber: ITypeFormField;
}
export interface InventoryItemFormDTO
{
	costPrice: ITypeFormField;
	currentStock: ITypeFormField;
	description: ITypeFormField;
	isStockItem: ITypeFormField;
	minimumStockThreshold: ITypeFormField;
	name: ITypeFormField;
	reorderLevel: ITypeFormField;
	salePrice: ITypeFormField;
	sku: ITypeFormField;
}
export interface AddStockFormDTO
{
	purchaseDate: ITypeFormField;
	purchasePrice: ITypeFormField;
	quantity: ITypeFormField;
}
export interface InventoryItemCategoryFormDTO
{
	description: ITypeFormField;
	name: ITypeFormField;
}
export interface ITypeForms
{
	accountRoleDTO: AccountRoleFormDTO;
	addStockFormDTO: AddStockFormDTO;
	appointmentTypeFormDTO: AppointmentTypeFormDTO;
	inventoryItemCategoryFormDTO: InventoryItemCategoryFormDTO;
	inventoryItemFormDTO: InventoryItemFormDTO;
	serviceCategoryFormDTO: ServiceCategoryFormDTO;
	staffMemberFormDTO: StaffMemberFormDTO;
}
export interface IPostInitialProfileDTO
{
	firstName: string;
	lastName: string;
	phoneNumber: string;
}
export interface IPostUserDTO
{
	abilityToCancel: boolean;
	activateEmailList: boolean;
	appointmentDistributionInMinutes: number;
	automaticAddCustomers: boolean;
	automaticCreateCustomers: boolean;
	bankAccount: string;
	bookingLink: string;
	bookingLocationName: string;
	cancellationBoxText: string;
	companyName: string;
	companyRole: number;
	connectedBookingLink: string;
	country: string;
	currency: string;
	customerCanSelectStaff: boolean;
	customerMergeType: CustomerMergeType;
	customerReceiptAndReminderMode: string;
	customerRequiredAddress: boolean;
	customerRequiredBirthdate: boolean;
	customerRequiredCity: boolean;
	customerRequiredEmail: boolean;
	customerRequiredEmailOptional: boolean;
	customerRequiredGender: boolean;
	customerRequiredName: boolean;
	customerRequiredPhoneNumber: boolean;
	customerRequiredPhoneNumberOptional: boolean;
	customerRequiredPostalCode: boolean;
	customerRequiredSSN: boolean;
	dateOfBirth: any;
	defaultPhoneNumberCountryCode: number;
	dueDateLength: number;
	emailAddress: string;
	emailAddressToReceive: string;
	enablePaymentModule: boolean;
	firstName: string;
	gender: string;
	hideIrrelevantDaysInCalendar: boolean;
	hoursToCancel: number;
	informationBoxText: string;
	intervalMinutes: number;
	kidLength: number;
	kidMod: number;
	language: string;
	lastName: string;
	maxDaysInAdvanceForBooking: number;
	minumumHourBeforeBooking: number;
	multipleServices: boolean;
	onlineTransactionProvider: OnlineTransactionProvider;
	organizationNumber: string;
	phoneNumber: string;
	phoneNumberToReceive: string;
	recieveEmailOnAppointment: boolean;
	recieveSMSOnAppointment: boolean;
	recieveWhatsAppOnAppointment: boolean;
	sector: number;
	sendAutomaticallyReceipt: boolean;
	sendAutomaticallyReminder: boolean;
	showInformationBox: boolean;
	showOccupiedHoursForAnonymousBooking: boolean;
	showServiceNameInEvent: number;
	showServicePrice: boolean;
	showStaffMemberInEvent: number;
	showTimeInEvent: number;
	signUpDate: any;
	ssn: number;
	subscriptionType: number;
	taxPercentage: number;
	timeFormat: string;
	timeZone: string;
	treatmentAdress: string;
	useCustomerAccounts: boolean;
	useKID: boolean;
	verificationType: VerificationType;
	whatsAppNumberToReceive: string;
}
export interface IPostFreshUserDTO
{
	bookingLink: string;
	companyName: string;
	defaultPhoneNumberCountryCode: number;
	emailAddress: string;
	language: string;
	timeFormat: string;
	timeZone: string;
}
export interface IUserDTO
{
	abilityToCancel: boolean;
	activateEmailList: boolean;
	appointmentDistributionInMinutes: number;
	automaticAddCustomers: boolean;
	automaticCreateCustomers: boolean;
	bankAccount: string;
	bookingLink: string;
	bookingLocationName: string;
	cancellationBoxText: string;
	companyId: number;
	companyName: string;
	companyRole: number;
	connectedBookingLink: string;
	country: string;
	currency: string;
	customerCanSelectStaff: boolean;
	customerMergeType: CustomerMergeType;
	customerReceiptAndReminderMode: string;
	customerRequiredAddress: boolean;
	customerRequiredBirthdate: boolean;
	customerRequiredCity: boolean;
	customerRequiredEmail: boolean;
	customerRequiredEmailOptional: boolean;
	customerRequiredGender: boolean;
	customerRequiredName: boolean;
	customerRequiredPhoneNumber: boolean;
	customerRequiredPhoneNumberOptional: boolean;
	customerRequiredPostalCode: boolean;
	customerRequiredSSN: boolean;
	dateOfBirth?: any;
	defaultPhoneNumberCountryCode: number;
	dueDateLength: number;
	emailAddress: string;
	emailAddressToReceive: string;
	emailVerified: boolean;
	enablePaymentModule: boolean;
	firstName: string;
	gender: string;
	hideIrrelevantDaysInCalendar: boolean;
	hoursToCancel: number;
	informationBoxText: string;
	intervalMinutes: number;
	kidLength: number;
	kidMod: number;
	language: string;
	lastName: string;
	lastYearlySubscription?: any;
	maxDaysInAdvanceForBooking: number;
	minumumHourBeforeBooking: number;
	multipleServices: boolean;
	onlineTransactionProvider: OnlineTransactionProvider;
	organizationNumber: string;
	phoneNumber: string;
	phoneNumberToReceive: string;
	profilePictureUrl: string;
	recieveEmailOnAppointment: boolean;
	recieveSMSOnAppointment: boolean;
	recieveWhatsAppOnAppointment: boolean;
	sector: number;
	sendAutomaticallyReceipt: boolean;
	sendAutomaticallyReminder: boolean;
	showInformationBox: boolean;
	showOccupiedHoursForAnonymousBooking: boolean;
	showServiceNameInEvent: number;
	showServicePrice: boolean;
	showStaffMemberInEvent: number;
	showTimeInEvent: number;
	signUpDate?: any;
	smsCredits: number;
	smsVerification: boolean;
	ssn: number;
	staffMemberId: number;
	subscriptionType: number;
	taxPercentage: number;
	timeFormat: string;
	timeZone: string;
	treatmentAdress: string;
	useCustomerAccounts: boolean;
	useKID: boolean;
	userId: string;
	verificationType: VerificationType;
	whatsAppNumberToReceive: string;
}
export interface IRouteDefinition
{
	method: HttpVerb;
	queryType: string;
	url: string;
}
export enum DisplayType {
	Text = 0,
	TextArea = 1,
	Number = 2,
	Switch = 3,
	ColorPicker = 4,
	Dropdown = 5,
	Date = 6
}
export interface IFieldMetadata
{
	isEditable: boolean;
	name: string;
	showWhen: string;
	type: DisplayType;
	validationRules: any[];
}
export interface IAppointmentTypeDTO
{
	autoApply: boolean;
	categoryId: number;
	categoryName: string;
	children: IAppointmentTypeDTO[];
	color: string;
	description: string;
	disablePrice: boolean;
	discountPrice: number;
	durationInMinutes: number;
	firstAvailable?: any;
	hideInBooking: boolean;
	id: number;
	image: string;
	isExtraService: boolean;
	mustPayOnline: boolean;
	order: number;
	payOnlinePrice: number;
	price: number;
	resources: IResourceDTO[];
	serviceDurationInMinutes: number;
	showCustomColor: boolean;
	title: string;
	unavailableForOnlineBooking: boolean;
}
export enum ValidationType {
	Required = 0,
	Min = 1,
	Max = 2,
	Number = 3,
	Email = 4,
	Phone = 5,
	Length = 6,
	Pattern = 7,
	Range = 8,
	Regex = 9
}
export interface IEditCustomerAccountDTO
{
	address: string;
	city: string;
	country: string;
	dateOfBirth: any;
	emailAddress: string;
	firstName: string;
	gender: string;
	password: string;
	phoneNumber: string;
	phoneNumberCountryCode: number;
	postalCode: string;
	ssn: string;
	userId: string;
}
export interface IPostCustomerAccountDTO
{
	address: string;
	adminId: string;
	city: string;
	dateOfBirth?: any;
	emailAddress: string;
	firstName: string;
	gender: string;
	lastName: string;
	password: string;
	phoneNumber: string;
	postalCode: string;
	ssn: string;
}
export interface ICustomerAccountAnonymousRequest
{
	adminId: string;
	login: string;
}
export interface ICustomerAccountRequest
{
	adminId: string;
	login: string;
	password: string;
}
export interface IResetPasswordByCode
{
	adminId: string;
	code: string;
	login: string;
	newPassword: string;
}
export interface IAppointmentChangeAsCustomAccount
{
	adminId: string;
	appointmentId: number;
	customerToken: string;
	day: number;
	hour: number;
	minute: number;
	month: number;
	phoneNumber: string;
	serviceIds: number[];
	year: number;
}
export interface IAppointmentAnonymousPostDTO
{
	address: string;
	appointmentTypeId: number;
	cancelUrl: string;
	comment: string;
	customerAccountIdentificationCode: string;
	day: number;
	emailAddress: string;
	emailReceipt: boolean;
	emailReminder: boolean;
	firstName: string;
	hour: number;
	lastName: string;
	minute: number;
	month: number;
	password: string;
	phoneNumber: string;
	postalCode: string;
	smsReceipt: boolean;
	smsReminder: boolean;
	userId: string;
	whatsappReceipt: boolean;
	whatsappReminder: boolean;
	year: number;
}
export interface ICustomerAccountDTO
{
	address: string;
	adminId: string;
	adminUserId: string;
	city: string;
	country: string;
	dateAdded?: any;
	dateOfBirth?: any;
	emailAddress: string;
	firstName: string;
	gender: string;
	id: number;
	lastName: string;
	password: string;
	phoneNumber: string;
	phoneNumberCountryCode: number;
	postalCode: string;
	ssn: string;
	verified: boolean;
}
export interface IStaffMemberCalendarIntervalsDTO
{
	intervals: ICalendarInterval[];
	staffMemberId: number;
}
export abstract class IUserController
{
	static GetBookingIntervalSettingsRoute: IRouteDefinition = { url: "User/current/bookingSettings/intervals", method: HttpVerb.Get, queryType: "" };
	static GetBookingSettingsRoute: IRouteDefinition = { url: "User/current/bookingSettings", method: HttpVerb.Get, queryType: "" };
	static GetCurrentUserRoute: IRouteDefinition = { url: "User/current", method: HttpVerb.Get, queryType: "" };
	static PatchUserRoute: IRouteDefinition = { url: "User/current", method: HttpVerb.Patch, queryType: "" };
	static PostPictureRoute: IRouteDefinition = { url: "User/current/Picture", method: HttpVerb.Put, queryType: "" };
	static PostUserRoute: IRouteDefinition = { url: "User/current", method: HttpVerb.Post, queryType: "" };
	static PutBookingSettingsRoute: IRouteDefinition = { url: "User/current/bookingSettings", method: HttpVerb.Put, queryType: "" };
	static PutUserProfileOnlyRoute: IRouteDefinition = { url: "User/current/profile", method: HttpVerb.Put, queryType: "" };
	static PutUserRoute: IRouteDefinition = { url: "User/current", method: HttpVerb.Put, queryType: "" };
	public abstract getCurrentUser(): Promise<IUserDTO>;
	public abstract getUser(userId: string): Promise<IUserDTO>;
	public abstract getUserByUsername(companyName: string): Promise<IUserDTO>;
	public abstract putUserProfileOnly(userDto: IPostInitialProfileDTO): Promise<any>;
	public abstract patchUser(patchDoc: any): Promise<any>;
	public abstract putUser(userDto: IPostUserDTO): Promise<any>;
	public abstract postUser(userDTO: IPostFreshUserDTO): Promise<any>;
	public abstract deleteCompanyEmployee(id: string): Promise<any>;
	public abstract getBookingIntervalSettings(staffMemberId?: number): Promise<IStaffMemberCalendarIntervalsDTO[]>;
	public abstract getBookingSettings(staffMemberId?: number): Promise<IUserBookingSettingsAsHoursAndMinutesDTO>;
	public abstract putBookingSettings(bookingSettingsDto: IUserBookingSettingsAsHoursAndMinutesDTO, staffMemberId?: number): Promise<any>;
	public abstract postPicture(file: any[]): Promise<any>;
}
export interface IAppointmentCustomFieldDTO
{
	appointmentId: number;
	bookingId: number;
	customFieldId: number;
	id: number;
	userId: string;
	value: string;
}
export interface IPostAppointmentCustomFieldDTO
{
	customFieldId: number;
	id: number;
	userId: string;
	value: string;
}
export interface IAuditLogDTO
{
	action: number;
	comment: string;
	firebaseUserId: string;
	firebaseUserName: string;
	hideForUser: boolean;
	id: number;
	message: string;
	time: any;
	type: number;
}
export interface IPostAuditLogDTO
{
	action: number;
	comment: string;
	hideForUser: boolean;
	message: string;
	type: number;
}
export interface ICustomFieldDTO
{
	archived: boolean;
	created: any;
	enabled: boolean;
	id: number;
	label: string;
	optional: boolean;
	userId: string;
}
export interface IInsuranceDTO
{
	address: string;
	bankAccount: string;
	city: string;
	country: string;
	emailAddress: string;
	id: number;
	name: string;
	phoneNumber: string;
	postalCode: string;
	userId: string;
}
export interface ISendInvoiceDTO
{
	cc: string;
	emailAddress: string;
	id: number;
	markAsPaid: boolean;
}
export interface IPostInvoiceDTO
{
	appointmentId: number;
	appointmentNumber: number;
	appointmentServicePrice: number;
	bankAccount: string;
	chargeAmount: number;
	companyEmail: string;
	companyName: string;
	companyPhone: string;
	customerAccountId: number;
	customerAddress: string;
	customerCity: string;
	customerCountry: string;
	customerEmailAddress: string;
	customerId: number;
	customerName: string;
	customerPhoneNumber: string;
	customerPostalCode: string;
	dischargeAmount: number;
	dueDays: number;
	id: number;
	invoiceNumber: number;
	kid: string;
	markAsPaid: boolean;
	organizationNumber: string;
	senderName: string;
	service: string;
	serviceQuantity: number;
	status: string;
	vat: number;
}
export interface IInvoiceDTO
{
	appointmentId: number;
	appointmentNumber: number;
	appointmentServicePrice: number;
	bankAccount: string;
	chargeAmount: number;
	companyEmail: string;
	companyName: string;
	companyPhone: string;
	customerAccountId: number;
	customerAddress: string;
	customerCity: string;
	customerCountry: string;
	customerEmailAddress: string;
	customerId: number;
	customerName: string;
	customerPhoneNumber: string;
	customerPostalCode: string;
	date: any;
	dischargeAmount: number;
	dueDate: any;
	dueDays: number;
	id: number;
	invoiceNumber: number;
	kid: string;
	organizationNumber: string;
	senderName: string;
	service: string;
	serviceQuantity: number;
	status: string;
	vat: number;
}
export interface INotificationDTO
{
	adminId: string;
	content: string;
	createdTime?: any;
	customerId: number;
	hasSeen: boolean;
	id: number;
	seenTime?: any;
	title: string;
}
export interface IResourceDTO
{
	amount: number;
	created: any;
	description: string;
	id: number;
	name: string;
	services: IAppointmentTypeDTO[];
	userId: string;
}
export interface IPostResourceDTO
{
	amount: number;
	description: string;
	name: string;
}
export interface IServiceCategoryDTO
{
	archived: boolean;
	id: number;
	order: number;
	title: string;
}
export interface IPostServiceCategoryDTO
{
	id: number;
	order: number;
	title: string;
}
export interface IStaffMemberDTO
{
	color: string;
	companyRole: number;
	dateOfBirth?: any;
	emailAddress: string;
	firstName: string;
	fridayCloseFromMidnight: number;
	fridayOpenFromMidnight: number;
	fullName: string;
	gender: string;
	id: number;
	imageUrl: string;
	lastName: string;
	mondayCloseFromMidnight: number;
	mondayOpenFromMidnight: number;
	notificationType: NotificationType;
	phoneNumber: string;
	profilePictureUrl: string;
	role: string;
	saturdayCloseFromMidnight: number;
	saturdayOpenFromMidnight: number;
	sector: number;
	services: IAppointmentTypeDTO[];
	sundayCloseFromMidnight: number;
	sundayOpenFromMidnight: number;
	thursdayCloseFromMidnight: number;
	thursdayOpenFromMidnight: number;
	tuesdayCloseFromMidnight: number;
	tuesdayOpenFromMidnight: number;
	vacationDays: IVacationDayDTO[];
	wednesdayCloseFromMidnight: number;
	wednesdayOpenFromMidnight: number;
	workingDays: any[];
}
export interface IStaffMemberBookingDTO extends IStaffMemberDTO
{
	nextAvailable?: any;
}
export interface IUpdateLogDTO
{
	hasRead: boolean;
	html: string;
	time: any;
	title: string;
}
export interface IVacationDayDTO
{
	adminId: string;
	day: number;
	month: number;
	year: number;
}
export enum NotificationType {
	None = 0,
	Email = 1,
	Whatsapp = 2,
	Sms = 3
}
export enum ShowStaffMemberInEvent {
	None = 0,
	Label = 1,
	Color = 2
}
export enum VerificationType {
	None = 0,
	Email = 1,
	Phone = 2
}
export enum CustomerMergeType {
	None = 0,
	Phone = 1
}
export interface IAppointmentInfoDTO
{
	adminEmail: string;
	adminNote: string;
	appointmentDuration: string;
	appointmentName: string;
	appointmentTypePrice: number;
	archived: boolean;
	canCancel: boolean;
	comment: string;
	companyAddress: string;
	companyName: string;
	created: any;
	createdByCustomer: boolean;
	customerAddress: string;
	customerCity: string;
	customerEmail: string;
	customerId: number;
	customerInsuranceId?: number;
	customerName: string;
	customerPhone: string;
	customerPhoneCountryCode: number;
	customerPostalCode: string;
	date?: any;
	day: number;
	description: string;
	duration: number;
	hasBeen: boolean;
	hour: number;
	id: number;
	isCustom: boolean;
	minute: number;
	month: number;
	number: number;
	paid: boolean;
	price: number;
	serviceIds: number[];
	staffMemberId: number;
	staffMemberName: string;
	status: string;
	year: number;
	dateIsWithinOneDay() : boolean;
	getDate() : any;
	getDateEnd() : any;
}
export interface IEditAppointmentDTO
{
	adminNote: string;
	bookedDateTime?: any;
	comment: string;
	customerId: number;
	customerName: string;
	day: number;
	duration: number;
	hour: number;
	id: number;
	minute: number;
	month: number;
	number: number;
	paid: boolean;
	price: number;
	serviceIds: number[];
	staffMemberId: number;
	status: string;
	year: number;
}
export interface IAppointmentDTO
{
	adminNote: string;
	appointmentTypeId: number;
	bookedDateTime?: any;
	comment: string;
	customerId: number;
	customerName: string;
	day: number;
	description: string;
	duration: number;
	hour: number;
	id: number;
	isCustom: boolean;
	minute: number;
	month: number;
	number: number;
	paid: boolean;
	price: number;
	status: string;
	year: number;
}
export interface IPostAppointmentDTO
{
	bookedDateTime?: any;
	comment: string;
	customerId: number;
	customerName: string;
	day: number;
	description: string;
	duration: number;
	emailReminder: boolean;
	forceCreate?: boolean;
	hour: number;
	id: number;
	minute: number;
	month: number;
	number: number;
	paid: boolean;
	price: number;
	serviceIds: number[];
	smsReminder: boolean;
	staffMemberId: number;
	status: string;
	whatsappReminder: boolean;
	year: number;
}
export interface IUserBookingSettingsDTO
{
	fridayCloseFromMidnight: number;
	fridayOpenFromMidnight: number;
	mondayCloseFromMidnight: number;
	mondayOpenFromMidnight: number;
	saturdayCloseFromMidnight: number;
	saturdayOpenFromMidnight: number;
	sundayCloseFromMidnight: number;
	sundayOpenFromMidnight: number;
	thursdayCloseFromMidnight: number;
	thursdayOpenFromMidnight: number;
	tuesdayCloseFromMidnight: number;
	tuesdayOpenFromMidnight: number;
	wednesdayCloseFromMidnight: number;
	wednesdayOpenFromMidnight: number;
}
export interface IUserBookingSettingsAsHoursAndMinutesDTO
{
	fridayCloseFromMidnight: string;
	fridayOpenFromMidnight: string;
	mondayCloseFromMidnight: string;
	mondayOpenFromMidnight: string;
	saturdayCloseFromMidnight: string;
	saturdayOpenFromMidnight: string;
	sundayCloseFromMidnight: string;
	sundayOpenFromMidnight: string;
	thursdayCloseFromMidnight: string;
	thursdayOpenFromMidnight: string;
	tuesdayCloseFromMidnight: string;
	tuesdayOpenFromMidnight: string;
	wednesdayCloseFromMidnight: string;
	wednesdayOpenFromMidnight: string;
}
export interface IBookingDTO
{
	address: string;
	canCancel: boolean;
	cancelExpiration: any;
	cancelId: string;
	cancelUrl: string;
	city: string;
	comment: string;
	created: any;
	customerAccountIdentificationCode: string;
	day: number;
	emailAddress: string;
	emailReceipt: boolean;
	emailReminder: boolean;
	firstName: string;
	hour: number;
	id: number;
	lastName: string;
	minute: number;
	month: number;
	password: string;
	phoneNumber: string;
	phoneNumberCountryCode: number;
	postalCode: string;
	sendReminder: boolean;
	serviceId: number;
	smsReceipt: boolean;
	smsReminder: boolean;
	staffMemberId: number;
	userId: string;
	verificationCode: string;
	verified: boolean;
	whatsappReceipt: boolean;
	whatsappReminder: boolean;
	year: number;
}
export interface IPostBookingDTO
{
	address: string;
	cancelUrl: string;
	city: string;
	comment: string;
	customerAccountIdentificationCode: string;
	customFields: IPostAppointmentCustomFieldDTO[];
	day: number;
	emailAddress: string;
	emailReceipt: boolean;
	emailReminder: boolean;
	firstName: string;
	hour: number;
	lastName: string;
	minute: number;
	month: number;
	password: string;
	phoneNumber: string;
	phoneNumberCountryCode: number;
	postalCode: string;
	serviceBookings: IServiceBookingPostDTO[];
	signupNewsletter: boolean;
	smsReceipt: boolean;
	smsReminder: boolean;
	staffMemberId: number;
	userId: string;
	whatsappReceipt: boolean;
	whatsappReminder: boolean;
	year: number;
}
export interface IBookingPageDTO
{
	id: string;
	name: string;
}
export interface ICustomerJournalDTO
{
	adminId: string;
	companyId: number;
	content: string;
	customerId: number;
	entryDate: any;
	id: number;
	title: string;
}
export interface IDebugLogDTO
{
	eventId: number;
	hasSeen: boolean;
	id: number;
	innerMessage: string;
	message: string;
	stackTrace: string;
	time: any;
}
export interface IEventDTO
{
	appointmentTime: any;
	comment: string;
	customerName: string;
	eventId: number;
	hasSeen: boolean;
	id: number;
	message: string;
	messageData: number;
	receipt: boolean;
	reminder: boolean;
	services: string;
	time: any;
}
export interface ICustomerStatisticsDTO
{
	balanceOverall: number;
	balanceThisMonth: number;
	ordersOverall: number;
	ordersThisMonth: number;
}
export interface ICustomerDTO
{
	address: string;
	city: string;
	country: string;
	createdByCustomer: boolean;
	dateAdded?: any;
	dateOfBirth?: any;
	emailAddress: string;
	firstName: string;
	gender: string;
	generalInfo: string;
	id: number;
	insuranceId: number;
	insuranceName: string;
	lastName: string;
	phoneNumber: string;
	phoneNumberCountryCode: string;
	postalCode: string;
	sendEmailReminder: boolean;
	sendSmsReminder: boolean;
	ssn: string;
}
export enum FormType {
	AppointmentType = 0,
	ServiceCategory = 1,
	AccountRole = 2,
	StaffMember = 3,
	InventoryItem = 4,
	AddStock = 5,
	InventoryItemCategory = 6
}
export abstract class IMetadataController
{
	public abstract getFormsMetadata(): Promise<IFormsMetadataDTO>;
}
export interface IPaginatedAppointmentResponseDTO
{
	items: IAppointmentInfoDTO[];
	itemsPerPage: number;
	page: number;
	sortBy: string;
	sortDesc: boolean;
	total: number;
}
export interface IAppointmentForWeeklyCalenderDTO
{
	adminNote: string;
	appointmentId: number;
	appointmentName: string;
	appointmentNumber: number;
	appointmentPrice: number;
	appointmentTypeIds: number[];
	booked: any;
	category: string;
	color: string;
	comment: string;
	createdByCustomer: boolean;
	customerEmail: string;
	customerId: number;
	customerPhone: string;
	customFields: [string,string][];
	day: number;
	duration: number;
	end: any;
	hour: number;
	isCustom: boolean;
	isRegularCustomer: boolean;
	minute: number;
	month: number;
	name: string;
	staffMemberId: number;
	staffMemberName: string;
	staffMembersProvidingService: number[];
	start: any;
	status: string;
	year: number;
}
export abstract class IAppointmentController
{
	static ArchiveAppointmentRoute: IRouteDefinition = { url: "Appointment/current", method: HttpVerb.Delete, queryType: "" };
	static ChangeAsCustomerAccountRoute: IRouteDefinition = { url: "Appointment/ChangeAsCustomerAccount", method: HttpVerb.Put, queryType: "" };
	static ChangeStaffMemberRoute: IRouteDefinition = { url: "Appointment/current/staffMember", method: HttpVerb.Patch, queryType: "" };
	static CheckAppointmentOverlapRoute: IRouteDefinition = { url: "Appointment/current/check-overlap", method: HttpVerb.Post, queryType: "" };
	static DeleteAppointmentByCustomerAccountLoginRoute: IRouteDefinition = { url: "Appointment/cancelByCustomer", method: HttpVerb.Post, queryType: "" };
	static DownloadExcelRapportRoute: IRouteDefinition = { url: "Appointment/current/download", method: HttpVerb.Get, queryType: "" };
	static EditAppointmentRoute: IRouteDefinition = { url: "Appointment/current", method: HttpVerb.Put, queryType: "" };
	static GetAppointmentByNumberRoute: IRouteDefinition = { url: "Appointment/current/number/{appointmentNumber}", method: HttpVerb.Get, queryType: "" };
	static GetAppointmentRoute: IRouteDefinition = { url: "Appointment/{appointmentId}", method: HttpVerb.Get, queryType: "" };
	static GetAppointmentsForAdminCustomerRoute: IRouteDefinition = { url: "Appointment/current/customer/{customerId}", method: HttpVerb.Get, queryType: "" };
	static GetAppointmentsForAdminForEventCalenderRoute: IRouteDefinition = { url: "Appointment/onEvent/forCalender/current", method: HttpVerb.Get, queryType: "" };
	static GetAppointmentsForAdminRoute: IRouteDefinition = { url: "Appointment/current", method: HttpVerb.Get, queryType: "IGetAppointmentsForAdminQuery" };
	static GetAppointmentsForMonthAdminForCalenderRoute: IRouteDefinition = { url: "Appointment/OnMonth/forCalender/current", method: HttpVerb.Get, queryType: "" };
	static GetAppointmentsForWeekAdminForCalenderRoute: IRouteDefinition = { url: "Appointment/onWeek/forCalender/current", method: HttpVerb.Get, queryType: "" };
	static GetAvailableHoursCacheRoute: IRouteDefinition = { url: "Appointment/availableHours/cache", method: HttpVerb.Post, queryType: "" };
	static GetAvailableHoursForBookingPOSTRoute: IRouteDefinition = { url: "Appointment/availableHours", method: HttpVerb.Post, queryType: "" };
	static GetAvailableHoursForMonthRoute: IRouteDefinition = { url: "Appointment/availablehours/month", method: HttpVerb.Post, queryType: "" };
	static GetCustomerAccountAppointmentsRoute: IRouteDefinition = { url: "Appointment/forCustomerAccount", method: HttpVerb.Post, queryType: "" };
	static GetFirstAvailableDayForBookingRoute: IRouteDefinition = { url: "Appointment/firstAvailableHourDay", method: HttpVerb.Post, queryType: "" };
	static PatchAppointmentRoute: IRouteDefinition = { url: "Appointment/current", method: HttpVerb.Patch, queryType: "" };
	static PostAppointmentRoute: IRouteDefinition = { url: "Appointment/current", method: HttpVerb.Post, queryType: "" };
	static SendEmailRemindersRoute: IRouteDefinition = { url: "Appointment/sendEmailReminders", method: HttpVerb.Post, queryType: "" };
	static VerifyAppointmentRoute: IRouteDefinition = { url: "Appointment/verify", method: HttpVerb.Post, queryType: "" };
	public abstract getFirstAvailableDayForBooking(request: IAvailableHoursRequestDTO): Promise<any | null>;
	public abstract getAvailableHoursCache(request: IAvailableHoursRequestDTO): Promise<ILoadedHourToBookDTO[]>;
	public abstract getAvailableHoursForBookingPOST(request: IAvailableHoursRequestDTO): Promise<IHourToBookDTO[]>;
	public abstract getAppointmentsForAdmin(query: Partial<typeof IAppointmentController.IGetAppointmentsForAdminQuery>): Promise<IPaginatedAppointmentResponseDTO>;
	public abstract downloadExcelRapport(): Promise<Blob>;
	public abstract getAppointmentByNumber(appointmentNumber: number): Promise<IAppointmentInfoDTO>;
	public abstract getAppointmentsForAdminCustomer(customerId: number): Promise<IAppointmentInfoDTO[]>;
	public abstract changeAsCustomerAccount(customerAccount: IAppointmentChangeAsCustomAccount): Promise<void>;
	public abstract getCustomerAccountAppointments(customerAccount: ICustomerAccountDTO): Promise<IAppointmentInfoDTO[]>;
	public abstract getAppointmentsForAdminForEventCalender(day: number, month: number, year: number): Promise<IAppointmentForWeeklyCalenderDTO[]>;
	public abstract getAppointmentsForMonthAdminForCalender(month: number, year: number): Promise<IAppointmentForWeeklyCalenderDTO[]>;
	public abstract getAppointmentsForWeekAdminForCalender(week: number, year: number, extraWeek: boolean): Promise<IAppointmentForWeeklyCalenderDTO[]>;
	public abstract getAvailableHoursForMonth(request: IAvailableHoursRequestDTO): Promise<ILoadedHourToBookDTO[]>;
	public abstract getAppointment(appointmentId: number): Promise<IAppointmentDTO>;
	public abstract editAppointment(appointment: IEditAppointmentDTO): Promise<any>;
	public abstract changeStaffMember(staffMemberId: number, appointmentId: number): Promise<any>;
	public abstract patchAppointment(patchDoc: any, appointmentId: number): Promise<any>;
	public abstract postNewBooking(bookingPost: IPostBookingDTO, userId: string): Promise<any>;
	public abstract postAppointment(appointment: IPostAppointmentDTO): Promise<any>;
	public abstract checkAppointmentOverlap(appointment: IPostAppointmentDTO): Promise<boolean>;
	public abstract archiveAppointment(id: number): Promise<any>;
	public abstract verifyAppointment(verificationCode: string, userId: string): Promise<void>;
	public abstract deleteAppointmentByCustomerAccountLogin(appointmentId: number, customerToken: string, phoneNumber: string, userId: string): Promise<any>;
	    public static IGetAppointmentsForAdminQuery: {
	        page: number | null;
	        itemsPerPage: number | null;
	        sortBy: string | null;
	        desc: string | null;
	        staffMemberId: number | null;
	    };
}
export abstract class ICustomerJournalsController
{
	static GetCustomerJournalByCustomerIdRoute: IRouteDefinition = { url: "CustomerJournal/current/customerId", method: HttpVerb.Get, queryType: "" };
	static GetCustomerJournalRoute: IRouteDefinition = { url: "CustomerJournal/current/{customerJournalId}", method: HttpVerb.Get, queryType: "" };
	static PostCustomerJournalRoute: IRouteDefinition = { url: "CustomerJournal/current", method: HttpVerb.Post, queryType: "" };
	static PutCustomerJournalRoute: IRouteDefinition = { url: "CustomerJournal/current", method: HttpVerb.Put, queryType: "" };
	public abstract getCustomerJournal(customerJournalId: number): Promise<ICustomerJournalDTO>;
	public abstract getCustomerJournalByCustomerId(customerId: number): Promise<ICustomerJournalDTO[]>;
	public abstract putCustomerJournal(newJournalInfo: any): Promise<void>;
	public abstract postCustomerJournal(customerJournal: any): Promise<void>;
}
export interface ICustomerTokenDataDTO
{
	appointments: IAppointmentInfoDTO[];
	customer: ICustomerDTO;
}
export interface ISubscriptionPlanInfoDTO
{
	cardInformation: string;
	monthlyPrice?: number;
	name: string;
	nextPayment?: any;
	previousPayment?: any;
	smsCosts?: number;
}
export abstract class ISubscriptionController
{
	static CancelSubscriptionRoute: IRouteDefinition = { url: "Subscription/cancel", method: HttpVerb.Post, queryType: "" };
	static CreateSubscriptionRoute: IRouteDefinition = { url: "Subscription/Create/current", method: HttpVerb.Post, queryType: "" };
	static GetPublishKeyRoute: IRouteDefinition = { url: "Subscription/PublicKey", method: HttpVerb.Get, queryType: "" };
	static GetSubscriptionRoute: IRouteDefinition = { url: "Subscription/current", method: HttpVerb.Get, queryType: "" };
	static StatusByUserIdRoute: IRouteDefinition = { url: "Subscription/StatusOnUser", method: HttpVerb.Get, queryType: "" };
	static StatusRoute: IRouteDefinition = { url: "Subscription/Status/current", method: HttpVerb.Get, queryType: "" };
	public abstract createSubscription(stripeEmail: string, stripeToken: string): Promise<any>;
	public abstract cancelSubscription(cancelAtTheEndOfTheMonth: boolean): Promise<any>;
	public abstract status(): Promise<string>;
	public abstract statusByUserId(userId: string): Promise<string>;
	public abstract getSubscription(): Promise<ISubscriptionPlanInfoDTO>;
	public abstract getPublishKey(): Promise<string>;
}
export abstract class IVacationDayController
{
	static AddVacationDaysRoute: IRouteDefinition = { url: "VacationDays/current", method: HttpVerb.Post, queryType: "IAddVacationDaysQuery" };
	static GetVacationDaysAnonymousRoute: IRouteDefinition = { url: "VacationDays/asAnonymous", method: HttpVerb.Get, queryType: "IGetVacationDaysAnonymousQuery" };
	static GetVacationDaysRoute: IRouteDefinition = { url: "VacationDays/current/{staffMemberId?}", method: HttpVerb.Get, queryType: "" };
	static RemoveVacationDayRoute: IRouteDefinition = { url: "VacationDays/current", method: HttpVerb.Delete, queryType: "IRemoveVacationDayQuery" };
	public abstract getVacationDays(staffMemberId?: number): Promise<IVacationDayDTO[]>;
	public abstract getVacationDaysAnonymous(query: Partial<typeof IVacationDayController.IGetVacationDaysAnonymousQuery>): Promise<IVacationDayDTO[]>;
	public abstract addVacationDays(query: Partial<typeof IVacationDayController.IAddVacationDaysQuery>): Promise<void>;
	public abstract removeVacationDay(query: Partial<typeof IVacationDayController.IRemoveVacationDayQuery>): Promise<void>;
	    public static IGetVacationDaysAnonymousQuery: {
	        userId: string;
	        serviceIds: string;
	    };
	    public static IAddVacationDaysQuery: {
	        vacationDaysString: string;
	        staffMemberId: number | null;
	    };
	    public static IRemoveVacationDayQuery: {
	        vacationDaysString: string;
	        staffMemberId: number | null;
	    };
}
export abstract class IWorkingDaysController
{
	static GetAllWorkingDaysAnonymousRoute: IRouteDefinition = { url: "WorkingDays/all", method: HttpVerb.Get, queryType: "" };
	static GetAllWorkingDaysRoute: IRouteDefinition = { url: "WorkingDays/all/current", method: HttpVerb.Get, queryType: "" };
	static ToggleWorkingHourDayRoute: IRouteDefinition = { url: "WorkingDays/current", method: HttpVerb.Put, queryType: "" };
	public abstract toggleWorkingHourDay(dayOfWeek: number, isWorking: boolean, staffMemberId?: number): Promise<void>;
	public abstract getAllWorkingDays(staffMemberId?: number): Promise<boolean[]>;
	public abstract getAllWorkingDaysAnonymous(userId: string, serviceIds: string): Promise<boolean[]>;
}
export interface IAvailableHoursRequestDTO
{
	day: number;
	month: number;
	serviceBookings: IServiceBookingPostDTO[];
	staffMemberId?: number;
	userID: string;
	year: number;
}
export interface IServiceBookingPostDTO
{
}
export abstract class ICustomersController
{
	static DeleteCustomerRoute: IRouteDefinition = { url: "Customer/current", method: HttpVerb.Delete, queryType: "" };
	static GetCustomerByTokenRoute: IRouteDefinition = { url: "Customer/FetchByToken/{customerToken}/{userId}/{phoneNumber}", method: HttpVerb.Get, queryType: "" };
	static GetCustomerRoute: IRouteDefinition = { url: "Customer/current/{customerId}", method: HttpVerb.Get, queryType: "" };
	static GetCustomersRoute: IRouteDefinition = { url: "Customer/current", method: HttpVerb.Get, queryType: "" };
	static GetCustomerStatsRoute: IRouteDefinition = { url: "Customer/current/statistics/{customerId}", method: HttpVerb.Get, queryType: "" };
	static PostCustomerRoute: IRouteDefinition = { url: "Customer/current", method: HttpVerb.Post, queryType: "" };
	static PutCustomerRoute: IRouteDefinition = { url: "Customer/current", method: HttpVerb.Put, queryType: "" };
	public abstract getCustomerByToken(customerToken: string, userId: string, phoneNumber: string): Promise<ICustomerTokenDataDTO>;
	public abstract getCustomerStats(customerId: number): Promise<ICustomerStatisticsDTO>;
	public abstract getCustomer(customerId: number): Promise<ICustomerDTO>;
	public abstract getCustomers(): Promise<ICustomerDTO[]>;
	public abstract putCustomer(customer: ICustomerDTO): Promise<any>;
	public abstract postCustomer(customer: any): Promise<void>;
	public abstract deleteCustomer(id: number): Promise<void>;
}
export abstract class ICustomFieldController
{
	static DeleteRoute: IRouteDefinition = { url: "CustomField/current", method: HttpVerb.Delete, queryType: "" };
	static GetAsAnonymousRoute: IRouteDefinition = { url: "CustomField/asAnonymous/{userId}", method: HttpVerb.Get, queryType: "" };
	static GetRoute: IRouteDefinition = { url: "CustomField/current", method: HttpVerb.Get, queryType: "" };
	static PostRoute: IRouteDefinition = { url: "CustomField/current", method: HttpVerb.Post, queryType: "" };
	static PutRoute: IRouteDefinition = { url: "CustomField/current", method: HttpVerb.Put, queryType: "" };
	public abstract get(): Promise<ICustomFieldDTO[]>;
	public abstract getAsAnonymous(userId: string): Promise<ICustomFieldDTO[]>;
	public abstract put(DTO: ICustomFieldDTO): Promise<void>;
	public abstract post(DTO: ICustomFieldDTO): Promise<void>;
	public abstract delete(id: number): Promise<void>;
}
export abstract class INotificationController
{
	static DeleteNotificationRoute: IRouteDefinition = { url: "Notification/current/{id}", method: HttpVerb.Delete, queryType: "" };
	static GetNotificationRoute: IRouteDefinition = { url: "Notification/{notificationId}", method: HttpVerb.Get, queryType: "" };
	static GetNotificationsForCurrentUserRoute: IRouteDefinition = { url: "Notification/all/current", method: HttpVerb.Get, queryType: "" };
	static GetNotificationsRoute: IRouteDefinition = { url: "Notification/all", method: HttpVerb.Get, queryType: "" };
	static MarkAllNotifcationsAsReadRoute: IRouteDefinition = { url: "Notification/all/MarkAsRead/current", method: HttpVerb.Put, queryType: "" };
	static MarkNotifcationAsReadRoute: IRouteDefinition = { url: "Notification/MarkAsRead/current/{notificationId}", method: HttpVerb.Put, queryType: "" };
	static PostNotificationRoute: IRouteDefinition = { url: "Notification/current", method: HttpVerb.Post, queryType: "" };
	public abstract getNotifications(): Promise<INotificationDTO[]>;
	public abstract getNotification(notificationId: number): Promise<INotificationDTO>;
	public abstract getNotificationsForCurrentUser(): Promise<any>;
	public abstract markNotifcationAsRead(notificationId: number): Promise<void>;
	public abstract markAllNotifcationsAsRead(): Promise<void>;
	public abstract postNotification(notification: any): Promise<void>;
	public abstract deleteNotification(id: number): Promise<void>;
}
export abstract class IPauseTimeController
{
	static DeletePauseTimeRoute: IRouteDefinition = { url: "PauseTimes/current", method: HttpVerb.Delete, queryType: "" };
	static GetPauseTimesRoute: IRouteDefinition = { url: "PauseTimes/current", method: HttpVerb.Get, queryType: "" };
	static PostPauseTimeRoute: IRouteDefinition = { url: "PauseTimes/current", method: HttpVerb.Post, queryType: "" };
	public abstract getPauseTimes(staffMemberId?: number): Promise<ISimplePauseTimeDTO[]>;
	public abstract postPauseTime(pauseTime: IPostPauseTimeDTO): Promise<ISimplePauseTimeDTO[]>;
	public abstract deletePauseTime(id: number): Promise<ISimplePauseTimeDTO[]>;
}
export abstract class IResourceController
{
	static AddResourceRoute: IRouteDefinition = { url: "Resources/current", method: HttpVerb.Post, queryType: "" };
	static AddServiceRoute: IRouteDefinition = { url: "Resources/current/service/{resourceId}/{serviceId}", method: HttpVerb.Put, queryType: "" };
	static DeleteResourceRoute: IRouteDefinition = { url: "Resources/current/{resourceId}", method: HttpVerb.Delete, queryType: "" };
	static EditResourceRoute: IRouteDefinition = { url: "Resources/current", method: HttpVerb.Put, queryType: "" };
	static GetResourcesRoute: IRouteDefinition = { url: "Resources/current", method: HttpVerb.Get, queryType: "" };
	public abstract getResources(): Promise<IResourceDTO[]>;
	public abstract editResource(resourceDTO: IResourceDTO): Promise<void>;
	public abstract addResource(resourceDTO: IPostResourceDTO): Promise<void>;
	public abstract deleteResource(resourceId: number): Promise<void>;
	public abstract addService(resourceId: number, serviceId: number): Promise<void>;
}
export abstract class IServiceCategoryController
{
	static ChangeOrderForCategoryRoute: IRouteDefinition = { url: "ServiceCategory/changeOrder/current", method: HttpVerb.Put, queryType: "" };
	static DeleteCategoryRoute: IRouteDefinition = { url: "ServiceCategory/current", method: HttpVerb.Delete, queryType: "" };
	static EditCategoryRoute: IRouteDefinition = { url: "ServiceCategory/current", method: HttpVerb.Put, queryType: "" };
	static GetCategoriesForAdminRoute: IRouteDefinition = { url: "ServiceCategory/current", method: HttpVerb.Get, queryType: "" };
	static PostCategoryRoute: IRouteDefinition = { url: "ServiceCategory/current", method: HttpVerb.Post, queryType: "" };
	public abstract getCategoriesForAdmin(): Promise<IServiceCategoryDTO[]>;
	public abstract changeOrderForCategory(categoryId: number, order: number): Promise<void>;
	public abstract postCategory(category: IServiceCategoryDTO): Promise<void>;
	public abstract editCategory(category: IPostServiceCategoryDTO): Promise<void>;
	public abstract deleteCategory(id: number): Promise<void>;
}
export abstract class IServiceController
{
	static AssignAppointmentTypeToUserRoute: IRouteDefinition = { url: "Service/AssignOrUnAssign/current", method: HttpVerb.Post, queryType: "" };
	static ChangeExtraServiceRoute: IRouteDefinition = { url: "Service/changeExtraService/current", method: HttpVerb.Put, queryType: "" };
	static ChangeOrderForServiceRoute: IRouteDefinition = { url: "Service/changeOrder/current", method: HttpVerb.Put, queryType: "" };
	static DeleteTreatmentTypeRoute: IRouteDefinition = { url: "Service/current", method: HttpVerb.Delete, queryType: "" };
	static EditServiceRoute: IRouteDefinition = { url: "Service/current", method: HttpVerb.Put, queryType: "" };
	static GetServicesAsAnonymousRoute: IRouteDefinition = { url: "Service/asAnonymous", method: HttpVerb.Get, queryType: "" };
	static GetServicesRoute: IRouteDefinition = { url: "Service/current", method: HttpVerb.Get, queryType: "IGetServicesQuery" };
	static GetTreatmentTypeRoute: IRouteDefinition = { url: "Service/{appointmentTypeId}", method: HttpVerb.Get, queryType: "" };
	static PostServiceRoute: IRouteDefinition = { url: "Service/current", method: HttpVerb.Post, queryType: "" };
	static PutAppointmentTypeRoute: IRouteDefinition = { url: "Service/changeCategoryOrder/current", method: HttpVerb.Put, queryType: "" };
	public abstract getTreatmentType(appointmentTypeId: number): Promise<any>;
	public abstract changeExtraService(extraServiceChange: any): Promise<void>;
	public abstract getServices(query: Partial<typeof IServiceController.IGetServicesQuery>): Promise<IAppointmentTypeDTO[]>;
	public abstract getServicesAsAnonymous(userId: string): Promise<IServiceGroupForBookingDTO[]>;
	public abstract putAppointmentType(id: number, categoryId: number, order: number): Promise<void>;
	public abstract changeOrderForService(serviceId: number, order: number): Promise<void>;
	public abstract editService(treatmentType: IAppointmentTypeDTO): Promise<void>;
	public abstract postService(treatmentType: IAppointmentTypeDTO): Promise<any>;
	public abstract assignAppointmentTypeToUser(treatmentTypeId: number): Promise<void>;
	public abstract deleteTreatmentType(id: number): Promise<void>;
	    public static IGetServicesQuery: {
	        extra: string | null;
	    };
}
export interface ICalendarInterval
{
	closeHour: number;
	closeMinute: number;
	openHour: number;
	openMinute: number;
}
export interface IWeekDayOpeningTimes
{
	closeMinutesFromMidnight: number;
	openMinutesFromMidnight: number;
}
export interface ICachedAvailableHours
{
	day: number;
	hoursToBook: IHourToBookDTO[];
	month: number;
	year: number;
}
export interface IPreLoadedHourToBookDTO extends ILoadedHourToBookDTO
{
}
export interface ILoadedHourToBookDTO
{
	day: number;
	hoursToBook: IHourToBookDTO[];
	id: string;
	iso: string;
	month: number;
	year: number;
}
export interface IHourToBookDTO
{
	appointmentEndLabel: string;
	backendLabel: string;
	date: any;
	label: string;
	getAppointmentEnd(length: number) : any;
}
export interface IInventoryItemRoutes
{
}
export const enum PermissionLevel {
	None = 0,
	Read = 1,
	ReadWrite = 2
}
export interface IPauseTimeDTO
{
	dayOfWeek: number;
	endFromMidNight: number;
	id: number;
	staffId: number;
	startFromMidNight: number;
}
export interface IPostPauseTimeDTO
{
	dayOfWeek: number;
	end: string;
	staffId: number;
	start: string;
}
export interface ISimplePauseTimeDTO
{
	dayOfWeek: number;
	end: string;
	id: number;
	staffId: number;
	start: string;
}
export interface IEmailListEntryDTO
{
	email: string;
	id: number;
	name: string;
	signupDate: any;
}
export interface IServiceGroupForBookingDTO
{
	order?: number;
	services: IAppointmentTypeDTO[];
	title: string;
}
export enum PaymentMethod {
	Cash = 0,
	Card = 1,
	Transfer = 2,
	Other = 3
}
export enum LedgerEntrySourceType {
	Appointment = 0,
	Order = 1,
	Manual = 2
}
export enum LedgerEntryStatus {
	Unpaid = 0,
	PartiallyPaid = 1,
	Paid = 2,
	Voided = 3
}
export enum LedgerEntryType {
	Charge = 0,
	Payment = 1,
	Refund = 2,
	Adjustment = 3
}
export enum OrderStatus {
	Pending = 0,
	Completed = 1,
	Cancelled = 2,
	Refunded = 3
}
export enum ExpenseCategory {
	Inventory = 0,
	Salaries = 1,
	Rent = 2,
	Utilities = 3,
	Other = 4
}
export enum RecurrenceInterval {
	Daily = 0,
	Weekly = 1,
	Monthly = 2,
	Quarterly = 3,
	Yearly = 4
}
export enum OnlineTransactionProvider {
	None = 0,
	PayU = 1,
	Stripe = 2,
	Vipps = 3
}
export interface IInventoryItemDTO
{
	category: IInventoryItemCategoryDTO;
	categoryId?: number;
	costPrice: number;
	createdAt: any;
	currentStock: number;
	description: string;
	id: number;
	imageUrl: string;
	isStockItem: boolean;
	minimumStockThreshold: number;
	name: string;
	reorderLevel: number;
	salePrice: number;
	sku: string;
	updatedAt?: any;
}
export interface IAddStockDTO
{
	batchNumber: string;
	inventoryItemId: number;
	purchaseDate: any;
	purchasePrice: number;
	quantity: number;
}
export interface IInventoryItemCategoryDTO
{
	createdAt: any;
	description: string;
	id: number;
	isActive: boolean;
	name: string;
	updatedAt?: any;
	userId: string;
}
export interface IPostInventoryItemCategoryDTO
{
	description: string;
	name: string;
}
export interface IUpdateInventoryItemCategoryDTO
{
	description: string;
	isActive: boolean;
	name: string;
}
export interface IRecurringExpenseDTO
{
	amount: number;
	category: ExpenseCategory;
	createdAt: any;
	endDate?: any;
	id: number;
	isActasdasdive: boolean;
	isActive: boolean;
	name: string;
	recurrenceInterval: RecurrenceInterval;
	startDate: any;
	updatedAt?: any;
}
export interface IOneTimeExpenseDTO
{
	amount: number;
	amountxxxx: number;
	category: ExpenseCategory;
	createdAt: any;
	id: number;
	name: string;
	notes: string;
	purchaseDate: any;
	receipt: string;
	updatedAt?: any;
}
export interface IPostRecurringExpenseDTO
{
	amount: number;
	category: ExpenseCategory;
	endDate?: any;
	name: string;
	recurrenceInterval: RecurrenceInterval;
	startDate: any;
}
export interface IPostOneTimeExpenseDTO
{
	amount: number;
	category: ExpenseCategory;
	name: string;
	notes: string;
	purchaseDate: any;
	receipt: string;
}
export interface IPostInventoryItemDTO
{
	categoryId?: number;
	costPrice: number;
	currentStock: number;
	description: string;
	imageUrl: string;
	isStockItem: boolean;
	minimumStockThreshold: number;
	name: string;
	reorderLevel: number;
	salePrice: number;
	sku: string;
}
export interface IPostOrderDTO
{
	appointmentId?: number;
	customerId: string;
	salesItems: IPostSalesItemDTO[];
	staffId: string;
}
export interface IPostSalesItemDTO
{
	inventoryItemId: number;
	quantity: number;
}
export interface IPostLedgerPaymentDTO
{
	allocations: IPostPaymentAllocationDTO[];
	amount: number;
	customerId: string;
	paymentMethod: PaymentMethod;
	reference: string;
}
export interface IPostPaymentAllocationDTO
{
	amount: number;
	ledgerEntryId: number;
}
export interface IAccountRoleDTO
{
	administration: PermissionLevel;
	appointmentsAndClients: PermissionLevel;
	id: number;
	name: string;
	settings: PermissionLevel;
	statisticsAndFinances: PermissionLevel;
}
export interface IAvailableDayRequestDTO
{
	day: number;
	month: number;
	serviceIds: number[];
	userId: string;
	year: number;
}
export interface IDailyProfitDTO
{
	earnings: number[];
	netProfit: number[];
	oneTimeExpenses: number[];
	recurringExpenses: number[];
	totalEarnings: number;
	totalExpenses: number[];
	totalExpensesSum: number;
	totalNetProfit: number;
	totalOneTimeExpenses: number;
	totalRecurringExpenses: number;
}
export interface IFirebaseUserDTO
{
	email: string;
	metadata: any;
	name: string;
	phoneNumber: string;
	role: IAccountRoleDTO;
	staffMember: IStaffMemberDTO;
	userId: string;
}
export interface IFormsMetadataDTO
{
	forms: { [key:string]: IFieldMetadata[] };
}
export interface IMonthlyProfitDTO
{
	dailyEarnings: number[];
	dailyNetProfit: number[];
	dailyOneTimeExpenses: number[];
	dailyRecurringExpenses: number[];
	dailyTotalExpenses: number[];
	totalEarnings: number;
	totalExpensesSum: number;
	totalNetProfit: number;
	totalOneTimeExpenses: number;
	totalRecurringExpenses: number;
}
export interface IStaffDailyEarningsDTO
{
	earnings: number[];
	staffId: number;
	staffName: string;
}
export interface IStaffStatisticsAppointmentDTO
{
	appointments: number[];
	staffId: number;
	staffName: string;
}
export interface IStaffMonthlyEarningsDTO
{
	earnings: number[];
	staffId: number;
	staffName: string;
}
export interface IUpdateInventoryItemDTO
{
	categoryId?: number;
	costPrice: number;
	description: string;
	minimumStockThreshold: number;
	name: string;
	reorderLevel: number;
	salePrice: number;
	sku: string;
}
export interface IYearlyEarningsComparisonDTO
{
	current: number[];
	previous: number[];
}
export interface IYearlyProfitDTO
{
	monthlyEarnings: number[];
	monthlyNetProfit: number[];
	monthlyOneTimeExpenses: number[];
	monthlyRecurringExpenses: number[];
	monthlyTotalExpenses: number[];
	totalEarnings: number;
	totalExpensesSum: number;
	totalNetProfit: number;
	totalOneTimeExpenses: number;
	totalRecurringExpenses: number;
}
export interface ILedgerEntryDTO
{
	amount: number;
	customerId: string;
	dateTime: any;
	description: string;
	id: number;
	sourceId: number;
	sourceType: LedgerEntrySourceType;
	status: LedgerEntryStatus;
	type: LedgerEntryType;
}
export interface ILedgerEntryDetailsDTO
{
	appointment: IAppointmentInfoDTO;
	ledgerEntry: ILedgerEntryDTO;
}
export interface ICreateAppointmentLedgerEntryDTO
{
	appointmentId: number;
}
export interface IOrderDTO
{
	appointmentId?: number;
	createdAt: any;
	customerId: string;
	id: number;
	orderDate: any;
	salesItems: ISalesItemDTO[];
	staffId: string;
	status: OrderStatus;
	totalAmount: number;
	updatedAt?: any;
}
export interface ISalesItemDTO
{
	id: number;
	inventoryItemId: number;
	orderId: number;
	price: number;
	quantity: number;
	totalPrice: number;
}
export interface ILedgerPaymentDTO
{
	allocations: IPaymentAllocationDTO[];
	amount: number;
	createdAt: any;
	customerId: string;
	dateTime: any;
	id: number;
	paymentMethod: PaymentMethod;
	reference: string;
}
export interface IPaymentAllocationDTO
{
	amount: number;
	createdAt: any;
	id: number;
	ledgerEntryId: number;
	ledgerPaymentId: number;
}
export abstract class IExpenseController
{
	static CreateOneTimeRoute: IRouteDefinition = { url: "Expense/current/onetime", method: HttpVerb.Post, queryType: "" };
	static CreateRecurringRoute: IRouteDefinition = { url: "Expense/current/recurring", method: HttpVerb.Post, queryType: "" };
	static DeleteOneTimeRoute: IRouteDefinition = { url: "Expense/current/onetime/{id}", method: HttpVerb.Delete, queryType: "" };
	static DeleteRecurringRoute: IRouteDefinition = { url: "Expense/current/recurring/{id}", method: HttpVerb.Delete, queryType: "" };
	static EditOneTimeRoute: IRouteDefinition = { url: "Expense/current/onetime/{id}", method: HttpVerb.Put, queryType: "" };
	static EditRecurringRoute: IRouteDefinition = { url: "Expense/current/recurring/{id}", method: HttpVerb.Put, queryType: "" };
	static GetAllOneTimeRoute: IRouteDefinition = { url: "Expense/current/onetime", method: HttpVerb.Get, queryType: "" };
	static GetAllRecurringRoute: IRouteDefinition = { url: "Expense/current/recurring", method: HttpVerb.Get, queryType: "" };
	public abstract getAllRecurring(): Promise<IRecurringExpenseDTO[]>;
	public abstract getAllOneTime(): Promise<IOneTimeExpenseDTO[]>;
	public abstract createRecurring(dto: any): Promise<IRecurringExpenseDTO>;
	public abstract createOneTime(dto: IPostOneTimeExpenseDTO): Promise<IOneTimeExpenseDTO>;
	public abstract deleteOneTime(id: number): Promise<any>;
	public abstract deleteRecurring(id: number): Promise<any>;
	public abstract editOneTime(id: number, dto: IPostOneTimeExpenseDTO): Promise<IOneTimeExpenseDTO>;
	public abstract editRecurring(id: number, dto: any): Promise<IRecurringExpenseDTO>;
}
export abstract class IOrderController
{
	static CreateOrderRoute: IRouteDefinition = { url: "Order/current", method: HttpVerb.Post, queryType: "" };
	static GetOrderRoute: IRouteDefinition = { url: "Order/current/{id}", method: HttpVerb.Get, queryType: "" };
	static GetOrdersRoute: IRouteDefinition = { url: "Order/current", method: HttpVerb.Get, queryType: "" };
	static ProcessPaymentRoute: IRouteDefinition = { url: "Order/current/payment", method: HttpVerb.Post, queryType: "" };
	public abstract createOrder(dto: IPostOrderDTO): Promise<IOrderDTO>;
	public abstract processPayment(dto: IPostLedgerPaymentDTO): Promise<ILedgerPaymentDTO>;
	public abstract getOrders(): Promise<IOrderDTO[]>;
	public abstract getOrder(id: number): Promise<IOrderDTO>;
}
export interface IFirebaseUserRecordDTO
{
	disabled: boolean;
	displayName: string;
	email: string;
	emailVerified: boolean;
	phoneNumber: string;
	photoUrl: string;
	tenantId: string;
	uid: string;
	userMetaData: any;
}
export abstract class IAdminController
{
	static GetTokenRoute: IRouteDefinition = { url: "admin/token/{uid}", method: HttpVerb.Get, queryType: "" };
	static GetUsersRoute: IRouteDefinition = { url: "admin/users", method: HttpVerb.Get, queryType: "" };
	public abstract getToken(uid: string): Promise<string>;
	public abstract getUsers(): Promise<IFirebaseUserRecordDTO[]>;
}
export abstract class IAccountRoleController
{
	static CreateRoleRoute: IRouteDefinition = { url: "AccountRole/current", method: HttpVerb.Post, queryType: "" };
	static DeleteRoleRoute: IRouteDefinition = { url: "AccountRole/current/{id}", method: HttpVerb.Delete, queryType: "" };
	static GetRoleRoute: IRouteDefinition = { url: "AccountRole/current/{id}", method: HttpVerb.Get, queryType: "" };
	static GetRolesRoute: IRouteDefinition = { url: "AccountRole/current", method: HttpVerb.Get, queryType: "" };
	static UpdateRoleRoute: IRouteDefinition = { url: "AccountRole/current/{id}", method: HttpVerb.Put, queryType: "" };
	public abstract getRoles(): Promise<IAccountRoleDTO[]>;
	public abstract getRole(id: number): Promise<IAccountRoleDTO>;
	public abstract createRole(roleDto: IAccountRoleDTO): Promise<IAccountRoleDTO>;
	public abstract updateRole(id: number, roleDto: IAccountRoleDTO): Promise<any>;
	public abstract deleteRole(id: number): Promise<any>;
}
export abstract class IAuditLogController
{
	static GetCurrentAuditLogsRoute: IRouteDefinition = { url: "AuditLog/current", method: HttpVerb.Get, queryType: "" };
	static PostAuditAnonymousRoute: IRouteDefinition = { url: "AuditLog/asAnonymous/{userId}", method: HttpVerb.Post, queryType: "" };
	static PostAuditRoute: IRouteDefinition = { url: "AuditLog/current", method: HttpVerb.Post, queryType: "" };
	public abstract getCurrentAuditLogs(ignoreFlag: number): Promise<IAuditLogDTO[]>;
	public abstract postAuditAnonymous(userId: string, dto: IPostAuditLogDTO): Promise<any>;
	public abstract postAudit(dto: IPostAuditLogDTO): Promise<any>;
}
export abstract class ICompanyController
{
	static CreateAndJoinCompanyRoute: IRouteDefinition = { url: "Company/CreateAndJoin/current", method: HttpVerb.Post, queryType: "" };
	static DeleteCompanyRoute: IRouteDefinition = { url: "Company/current", method: HttpVerb.Delete, queryType: "" };
	static GetConnectionsRoute: IRouteDefinition = { url: "Company/connect", method: HttpVerb.Get, queryType: "" };
	static GetCurrentCompanyRoute: IRouteDefinition = { url: "Company/current", method: HttpVerb.Get, queryType: "" };
	static JoinCompanyRoute: IRouteDefinition = { url: "Company/join/current", method: HttpVerb.Put, queryType: "" };
	public abstract getConnections(id: string): Promise<IBookingPageDTO[]>;
	public abstract getCompanies(): Promise<ICompanyDTO[]>;
	public abstract getCurrentCompany(): Promise<ICompanyDTO>;
	public abstract getCompany(id: number): Promise<ICompanyDTO>;
	public abstract postCompany(companyDTO: ICompanyDTO): Promise<void>;
	public abstract joinCompany(companyId: number): Promise<void>;
	public abstract createAndJoinCompany(companyDTO: ICompanyDTO): Promise<void>;
	public abstract deleteCompany(): Promise<void>;
}
export abstract class ICustomerAccountController
{
	static GetCustomerAccountByPhoneNumberAndPasswordRoute: IRouteDefinition = { url: "CustomerAccount/byPhoneAndPassword", method: HttpVerb.Post, queryType: "" };
	static GetCustomerAccountByPhoneNumberRoute: IRouteDefinition = { url: "CustomerAccount/byPhone", method: HttpVerb.Post, queryType: "" };
	static ResetPasswordByCodeRoute: IRouteDefinition = { url: "CustomerAccount/resetPasswordByCode", method: HttpVerb.Post, queryType: "" };
	static ResetPasswordRoute: IRouteDefinition = { url: "CustomerAccount/resetPassword", method: HttpVerb.Post, queryType: "" };
	public abstract resetPasswordByCode(resetInfo: IResetPasswordByCode): Promise<any>;
	public abstract putCustomerAccount(customerAccount: IEditCustomerAccountDTO): Promise<any>;
	public abstract resetPassword(req: ICustomerAccountAnonymousRequest): Promise<any>;
	public abstract getCustomerAccountByPhoneNumber(req: ICustomerAccountAnonymousRequest): Promise<void>;
	public abstract getCustomerAccountByPhoneNumberAndPassword(login: ICustomerAccountRequest): Promise<ICustomerAccountDTO>;
	public abstract createCustomerAccount(customerAccount: IPostCustomerAccountDTO): Promise<any>;
}
export abstract class IDebugLogController
{
	static GetEventsRoute: IRouteDefinition = { url: "DebugLog/current", method: HttpVerb.Get, queryType: "" };
	public abstract getEvents(): Promise<IDebugLogDTO[]>;
}
export abstract class IEmailListController
{
	static GetRoute: IRouteDefinition = { url: "EmailList/current", method: HttpVerb.Get, queryType: "" };
	public abstract get(): Promise<IEmailListEntryDTO[]>;
}
export interface IPaginatedEventsResponseDTO
{
	items: IEventDTO[];
	itemsPerPage: number;
	page: number;
	sortBy: string;
	sortDesc: boolean;
	total: number;
}
export abstract class IEventsController
{
	static GetEventsRoute: IRouteDefinition = { url: "Event/current", method: HttpVerb.Get, queryType: "IGetEventsQuery" };
	static GetUnSeenEventsRoute: IRouteDefinition = { url: "Event/current/unSeen", method: HttpVerb.Get, queryType: "" };
	static MarkAsReadRoute: IRouteDefinition = { url: "Event/current/markAsRead", method: HttpVerb.Post, queryType: "" };
	public abstract getUnSeenEvents(): Promise<number>;
	public abstract markAsRead(): Promise<IEventDTO[]>;
	public abstract getEvents(query: Partial<typeof IEventsController.IGetEventsQuery>): Promise<IPaginatedEventsResponseDTO>;
	    public static IGetEventsQuery: {
	        page: number | null;
	        itemsPerPage: number | null;
	        sortBy: string | null;
	        desc: string | null;
	    };
}
export abstract class IFirebaseUserController
{
	static DeleteFirebaseUserRoute: IRouteDefinition = { url: "FirebaseUser/current/{firebaseUserId}", method: HttpVerb.Delete, queryType: "" };
	static GetAllRoute: IRouteDefinition = { url: "FirebaseUser/current/all", method: HttpVerb.Get, queryType: "" };
	static GetCurrentFirebaseUserRoute: IRouteDefinition = { url: "FirebaseUser/current", method: HttpVerb.Get, queryType: "" };
	static PatchFirebaseUserRoute: IRouteDefinition = { url: "FirebaseUser/current", method: HttpVerb.Patch, queryType: "" };
	static PostFirebaseAccountAsyncRoute: IRouteDefinition = { url: "FirebaseUser/current", method: HttpVerb.Post, queryType: "" };
	public abstract getCurrentFirebaseUser(): Promise<IFirebaseUserDTO>;
	public abstract getAll(): Promise<IFirebaseUserDTO[]>;
	public abstract postFirebaseAccountAsync(staffMember: any): Promise<any>;
	public abstract patchFirebaseUser(patchDoc: any, firebaseUserId: string): Promise<any>;
	public abstract deleteFirebaseUser(firebaseUserId: string): Promise<any>;
}
export abstract class IGoogleCalendarController
{
	public abstract syncAppWithCalendar(): Promise<string>;
	public abstract revokeToken(staffMemberID: number): Promise<any>;
	public abstract authenticateByCode(code: any): Promise<any>;
}
export abstract class IInventoryItemCategoryController
{
	static CreateCategoryRoute: IRouteDefinition = { url: "InventoryItemCategory/current", method: HttpVerb.Post, queryType: "" };
	static DeleteCategoryRoute: IRouteDefinition = { url: "InventoryItemCategory/current/{id}", method: HttpVerb.Delete, queryType: "" };
	static GetCategoriesRoute: IRouteDefinition = { url: "InventoryItemCategory/current", method: HttpVerb.Get, queryType: "" };
	static GetCategoryRoute: IRouteDefinition = { url: "InventoryItemCategory/current/{id}", method: HttpVerb.Get, queryType: "" };
	static UpdateCategoryRoute: IRouteDefinition = { url: "InventoryItemCategory/current/{id}", method: HttpVerb.Put, queryType: "" };
	public abstract getCategories(): Promise<IInventoryItemCategoryDTO[]>;
	public abstract getCategory(id: number): Promise<IInventoryItemCategoryDTO>;
	public abstract createCategory(categoryDto: IPostInventoryItemCategoryDTO): Promise<IInventoryItemCategoryDTO>;
	public abstract updateCategory(id: number, categoryDto: IUpdateInventoryItemCategoryDTO): Promise<any>;
	public abstract deleteCategory(id: number): Promise<any>;
}
export abstract class IInventoryItemController
{
	static AddStockRoute: IRouteDefinition = { url: "InventoryItem/current/stock", method: HttpVerb.Post, queryType: "" };
	static CreateRoute: IRouteDefinition = { url: "InventoryItem/current", method: HttpVerb.Post, queryType: "" };
	static DeleteRoute: IRouteDefinition = { url: "InventoryItem/current/{id}", method: HttpVerb.Delete, queryType: "" };
	static GetAllRoute: IRouteDefinition = { url: "InventoryItem/current", method: HttpVerb.Get, queryType: "" };
	static GetByIdRoute: IRouteDefinition = { url: "InventoryItem/current/{id}", method: HttpVerb.Get, queryType: "" };
	static GetLowStockItemsRoute: IRouteDefinition = { url: "InventoryItem/current/low-stock", method: HttpVerb.Get, queryType: "" };
	static UpdateRoute: IRouteDefinition = { url: "InventoryItem/current/{id}", method: HttpVerb.Put, queryType: "" };
	static UploadItemPictureRoute: IRouteDefinition = { url: "InventoryItem/current/{id}/picture", method: HttpVerb.Put, queryType: "" };
	public abstract getAll(): Promise<IInventoryItemDTO[]>;
	public abstract getById(id: number): Promise<IInventoryItemDTO>;
	public abstract create(dto: IPostInventoryItemDTO): Promise<IInventoryItemDTO>;
	public abstract getLowStockItems(): Promise<IInventoryItemDTO[]>;
	public abstract addStock(dto: IAddStockDTO): Promise<IInventoryItemDTO>;
	public abstract update(id: number, dto: IUpdateInventoryItemDTO): Promise<IInventoryItemDTO>;
	public abstract delete(id: number): Promise<any>;
	public abstract uploadItemPicture(file: any[], id: number): Promise<any>;
}
export abstract class ILedgerEntriesController
{
	static CreateFromAppointmentRoute: IRouteDefinition = { url: "LedgerEntries/current/from-appointment", method: HttpVerb.Post, queryType: "" };
	static GetCustomerBalanceRoute: IRouteDefinition = { url: "LedgerEntries/current/customer/{customerId}/balance", method: HttpVerb.Get, queryType: "" };
	static GetCustomerEntriesRoute: IRouteDefinition = { url: "LedgerEntries/current/customer/{customerId}", method: HttpVerb.Get, queryType: "" };
	static GetEntryWithAppointmentDetailsRoute: IRouteDefinition = { url: "LedgerEntries/current/{id}/details", method: HttpVerb.Get, queryType: "" };
	static HandleArchivedAppointmentRoute: IRouteDefinition = { url: "LedgerEntries/current/handle-archived/{appointmentId}", method: HttpVerb.Post, queryType: "" };
	public abstract getCustomerEntries(customerId: string): Promise<ILedgerEntryDTO[]>;
	public abstract getCustomerBalance(customerId: string): Promise<number>;
	public abstract getEntryWithAppointmentDetails(id: number): Promise<ILedgerEntryDetailsDTO>;
	public abstract createFromAppointment(dto: ICreateAppointmentLedgerEntryDTO): Promise<ILedgerEntryDTO>;
	public abstract handleArchivedAppointment(appointmentId: number): Promise<any>;
}
export abstract class IPaymentController
{
	public abstract initiatePayment(request: any): Promise<any>;
	public abstract paymentCallback(request: any): Promise<any>;
}
export abstract class IStatisticsController
{
	static GetAllYearProfitRoute: IRouteDefinition = { url: "Statistics/current/profit/all-years", method: HttpVerb.Get, queryType: "" };
	static GetAppointmentsSplitByDayAndStaffRoute: IRouteDefinition = { url: "Statistics/current/appointments/splitByDayAndStaff", method: HttpVerb.Get, queryType: "" };
	static GetAppointmentsSplitByDayRoute: IRouteDefinition = { url: "Statistics/current/appointments/splitByDay", method: HttpVerb.Get, queryType: "" };
	static GetAppointmentsSplitByMonthAndStaffRoute: IRouteDefinition = { url: "Statistics/current/appointments/splitByMonthAndStaff", method: HttpVerb.Get, queryType: "" };
	static GetAppointmentsSplitByMonthRoute: IRouteDefinition = { url: "Statistics/current/appointments/splitByMonth", method: HttpVerb.Get, queryType: "" };
	static GetCustomerBookedStatsRoute: IRouteDefinition = { url: "Statistics/current/customerBooked/", method: HttpVerb.Get, queryType: "" };
	static GetMoneyMadeRoute: IRouteDefinition = { url: "Statistics/current/moneyMade", method: HttpVerb.Get, queryType: "" };
	static GetMoneyMadeSplitByDayAndStaffRoute: IRouteDefinition = { url: "Statistics/current/moneyMade/splitByDayAndStaff", method: HttpVerb.Get, queryType: "" };
	static GetMoneyMadeSplitByDayRoute: IRouteDefinition = { url: "Statistics/current/moneyMade/splitByDay", method: HttpVerb.Get, queryType: "" };
	static GetMoneyMadeSplitByMonthAndStaffRoute: IRouteDefinition = { url: "Statistics/current/moneyMade/splitByMonthAndStaff", method: HttpVerb.Get, queryType: "" };
	static GetMonthlyProfitRoute: IRouteDefinition = { url: "Statistics/current/profit/monthly", method: HttpVerb.Get, queryType: "" };
	static GetNumberOfAppointmentsRoute: IRouteDefinition = { url: "Statistics/current/appointments", method: HttpVerb.Get, queryType: "" };
	static GetServicesBookedStatsRoute: IRouteDefinition = { url: "Statistics/current/servicesBooked/", method: HttpVerb.Get, queryType: "" };
	static GetYearlyProfitRoute: IRouteDefinition = { url: "Statistics/current/profit/yearly", method: HttpVerb.Get, queryType: "" };
	static NewCustomersRoute: IRouteDefinition = { url: "Statistics/current/newCustomers/", method: HttpVerb.Get, queryType: "" };
	public abstract getNumberOfAppointments(day: number, month: number, year: number, staffMemberId: number): Promise<number>;
	public abstract getMoneyMade(day: number, month: number, year: number, staffMemberId: number): Promise<number>;
	public abstract getMoneyMadeSplitByMonth(year: number, staffMemberId: number): Promise<IYearlyEarningsComparisonDTO>;
	public abstract getMoneyMadeSplitByDay(year: number, month: number, staffMemberId: number): Promise<IDailyProfitDTO>;
	public abstract newCustomers(year: number): Promise<INewCustomerStatsDTO>;
	public abstract getCustomerBookedStats(year: number, month: number): Promise<ICustomerStatDTO[]>;
	public abstract getServicesBookedStats(year: number, month: number, staffMemberId?: number): Promise<IServiceStatDTO[]>;
	public abstract getMoneyMadeSplitByMonthAndStaff(year: number): Promise<IStaffMonthlyEarningsDTO[]>;
	public abstract getMoneyMadeSplitByDayAndStaff(year: number, month: number): Promise<IStaffDailyEarningsDTO[]>;
	public abstract getAppointmentsSplitByMonthAndStaff(year: number): Promise<IStaffStatisticsAppointmentDTO[]>;
	public abstract getAppointmentsSplitByDayAndStaff(year: number, month: number): Promise<IStaffStatisticsAppointmentDTO[]>;
	public abstract getAppointmentsSplitByMonth(year: number, staffMemberId: number): Promise<IYearlyEarningsComparisonDTO>;
	public abstract getAppointmentsSplitByDay(year: number, month: number, staffMemberId: number): Promise<number[]>;
	public abstract getMonthlyProfit(year: number, month: number, staffMemberId: number): Promise<IMonthlyProfitDTO>;
	public abstract getYearlyProfit(year: number, staffMemberId: number): Promise<IYearlyProfitDTO>;
	public abstract getAllYearProfit(staffMemberId: number): Promise<{ [key:number]: IYearlyProfitDTO }>;
}
export interface IStaffMemberAnonymousRequestDTO
{
	serviceBookings: IServiceBookingPostDTO[];
}
export abstract class IInsuranceController
{
	static DeleteRoute: IRouteDefinition = { url: "Insurance/current", method: HttpVerb.Delete, queryType: "" };
	static GetByIdRoute: IRouteDefinition = { url: "Insurance/current/{id}", method: HttpVerb.Get, queryType: "" };
	static GetRoute: IRouteDefinition = { url: "Insurance/current", method: HttpVerb.Get, queryType: "" };
	static PostRoute: IRouteDefinition = { url: "Insurance/current", method: HttpVerb.Post, queryType: "" };
	static PutRoute: IRouteDefinition = { url: "Insurance/current", method: HttpVerb.Put, queryType: "" };
	public abstract put(insurance: IInsuranceDTO): Promise<IInsuranceDTO>;
	public abstract post(insurance: IInsuranceDTO): Promise<void>;
	public abstract get(): Promise<IInsuranceDTO[]>;
	public abstract delete(id: number): Promise<void>;
	public abstract getById(id: number): Promise<IInsuranceDTO>;
}
export abstract class IInvoiceController
{
	static ChangeStatusRoute: IRouteDefinition = { url: "Invoice/status/current", method: HttpVerb.Put, queryType: "" };
	static GetInvoiceNumberRoute: IRouteDefinition = { url: "Invoice/invoiceNumber/current", method: HttpVerb.Get, queryType: "" };
	static GetInvoicesForAppointmentRoute: IRouteDefinition = { url: "Invoice/forAppointment/current", method: HttpVerb.Get, queryType: "" };
	static GetInvoicesForCustomerRoute: IRouteDefinition = { url: "Invoice/forCustomer/current", method: HttpVerb.Get, queryType: "" };
	static GetPdfRoute: IRouteDefinition = { url: "Invoice/pdf/current", method: HttpVerb.Get, queryType: "" };
	static GetRoute: IRouteDefinition = { url: "Invoice/current", method: HttpVerb.Get, queryType: "" };
	static PostRoute: IRouteDefinition = { url: "Invoice/current", method: HttpVerb.Post, queryType: "" };
	static SendInvoiceRoute: IRouteDefinition = { url: "Invoice/send/current", method: HttpVerb.Post, queryType: "" };
	public abstract get(): Promise<IInvoiceDTO[]>;
	public abstract changeStatus(invoiceId: number, status: string): Promise<void>;
	public abstract sendInvoice(dto: ISendInvoiceDTO): Promise<void>;
	public abstract post(dto: IPostInvoiceDTO): Promise<IInvoiceDTO>;
	public abstract getInvoiceNumber(): Promise<number>;
	public abstract getInvoicesForCustomer(id: number): Promise<IInvoiceDTO[]>;
	public abstract getInvoicesForAppointment(appointmentId: number): Promise<IInvoiceDTO[]>;
	public abstract getPdf(invoiceId: number): Promise<any>;
}
export abstract class IStaffMemberController
{
	static DeleteStaffMemberRoute: IRouteDefinition = { url: "StaffMember/current/{staffMemberId}", method: HttpVerb.Delete, queryType: "" };
	static EditServiceStateOnStaffMemberRoute: IRouteDefinition = { url: "StaffMember/current/service/{staffMemberId}/{serviceId}/{enabled}", method: HttpVerb.Put, queryType: "" };
	static GetStaffMemberCountRoute: IRouteDefinition = { url: "StaffMember/count/{adminId}", method: HttpVerb.Get, queryType: "" };
	static GetStaffMembersAsAnonymousRoute: IRouteDefinition = { url: "StaffMember/asAnonymous/{adminId}", method: HttpVerb.Post, queryType: "" };
	static GetStaffMembersRoute: IRouteDefinition = { url: "StaffMember/current", method: HttpVerb.Get, queryType: "" };
	static PatchStaffMemberRoute: IRouteDefinition = { url: "StaffMember/current/{staffMemberId}", method: HttpVerb.Patch, queryType: "" };
	static PostStaffMemberRoute: IRouteDefinition = { url: "StaffMember/current", method: HttpVerb.Post, queryType: "" };
	static StaffMemberHasOAuthRoute: IRouteDefinition = { url: "StaffMember/current/hasOAuth/{staffMemberID}", method: HttpVerb.Get, queryType: "" };
	static UploadStaffMemberPictureRoute: IRouteDefinition = { url: "StaffMember/current/picture/{staffMemberId}", method: HttpVerb.Put, queryType: "" };
	public abstract staffMemberHasOAuth(staffMemberID: number): Promise<boolean>;
	public abstract getStaffMembers(): Promise<IStaffMemberDTO[]>;
	public abstract editServiceStateOnStaffMember(staffMemberId: number, serviceId: number, enabled: boolean): Promise<void>;
	public abstract postStaffMember(staffMember: IStaffMemberDTO): Promise<any>;
	public abstract patchStaffMember(patchDoc: any, staffMemberId: number): Promise<any>;
	public abstract deleteStaffMember(staffMemberId: number): Promise<any>;
	public abstract uploadStaffMemberPicture(file: any[], staffMemberId: number): Promise<any>;
	public abstract getStaffMembersAsAnonymous(adminId: string, request: IStaffMemberAnonymousRequestDTO): Promise<IStaffMemberBookingDTO[]>;
	public abstract getStaffMemberCount(adminId: string): Promise<number>;
}
export class FormMetadata
{
	static accountrole: IFieldMetadata[] = [{
                    name: 'Name',
                    type: DisplayType.Text,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }]
                },
{
                    name: 'AppointmentsAndClients',
                    type: DisplayType.Dropdown,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }]
                },
{
                    name: 'Settings',
                    type: DisplayType.Dropdown,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }]
                },
{
                    name: 'StatisticsAndFinances',
                    type: DisplayType.Dropdown,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }]
                },
{
                    name: 'Administration',
                    type: DisplayType.Dropdown,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }]
                }];
	static addstock: IFieldMetadata[] = [{
                    name: 'Quantity',
                    type: DisplayType.Number,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }, { type: ValidationType.Min, value: 1 }, { type: ValidationType.Max, value: 2147483647 }]
                },
{
                    name: 'PurchasePrice',
                    type: DisplayType.Number,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }, { type: ValidationType.Min, value: 0 }, { type: ValidationType.Max, value: 1.7976931348623157E+308 }]
                },
{
                    name: 'PurchaseDate',
                    type: DisplayType.Date,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }]
                }];
	static appointmenttype: IFieldMetadata[] = [{
                    name: 'Title',
                    type: DisplayType.Text,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }]
                },
{
                    name: 'Description',
                    type: DisplayType.TextArea,
                    isEditable: true,
                    showWhen: null,
                    validationRules: []
                },
{
                    name: 'Price',
                    type: DisplayType.Number,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Min, value: 0 }, { type: ValidationType.Max, value: 2147483647 }]
                },
{
                    name: 'DurationInMinutes',
                    type: DisplayType.Number,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }, { type: ValidationType.Min, value: 1 }, { type: ValidationType.Max, value: 720 }]
                },
{
                    name: 'HideInBooking',
                    type: DisplayType.Switch,
                    isEditable: true,
                    showWhen: null,
                    validationRules: []
                },
{
                    name: 'AutoApply',
                    type: DisplayType.Switch,
                    isEditable: true,
                    showWhen: null,
                    validationRules: []
                },
{
                    name: 'IsExtraService',
                    type: DisplayType.Switch,
                    isEditable: true,
                    showWhen: null,
                    validationRules: []
                },
{
                    name: 'ShowCustomColor',
                    type: DisplayType.Switch,
                    isEditable: true,
                    showWhen: null,
                    validationRules: []
                },
{
                    name: 'Color',
                    type: DisplayType.ColorPicker,
                    isEditable: true,
                    showWhen: 'ShowCustomColor',
                    validationRules: []
                },
{
                    name: 'DisablePrice',
                    type: DisplayType.Switch,
                    isEditable: true,
                    showWhen: null,
                    validationRules: []
                },
{
                    name: 'MustPayOnline',
                    type: DisplayType.Switch,
                    isEditable: true,
                    showWhen: null,
                    validationRules: []
                },
{
                    name: 'PayOnlinePrice',
                    type: DisplayType.Number,
                    isEditable: true,
                    showWhen: 'MustPayOnline',
                    validationRules: [{ type: ValidationType.Min, value: 0 }, { type: ValidationType.Max, value: 2147483647 }]
                }];
	static inventoryitem: IFieldMetadata[] = [{
                    name: 'Name',
                    type: DisplayType.Text,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }]
                },
{
                    name: 'Description',
                    type: DisplayType.TextArea,
                    isEditable: true,
                    showWhen: null,
                    validationRules: []
                },
{
                    name: 'SalePrice',
                    type: DisplayType.Number,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }]
                },
{
                    name: 'IsStockItem',
                    type: DisplayType.Switch,
                    isEditable: true,
                    showWhen: null,
                    validationRules: []
                },
{
                    name: 'CostPrice',
                    type: DisplayType.Number,
                    isEditable: true,
                    showWhen: 'IsStockItem',
                    validationRules: [{ type: ValidationType.Required }]
                },
{
                    name: 'CurrentStock',
                    type: DisplayType.Number,
                    isEditable: true,
                    showWhen: 'IsStockItem',
                    validationRules: [{ type: ValidationType.Required }]
                },
{
                    name: 'MinimumStockThreshold',
                    type: DisplayType.Number,
                    isEditable: true,
                    showWhen: 'IsStockItem',
                    validationRules: [{ type: ValidationType.Required }]
                },
{
                    name: 'ReorderLevel',
                    type: DisplayType.Number,
                    isEditable: true,
                    showWhen: 'IsStockItem',
                    validationRules: []
                },
{
                    name: 'SKU',
                    type: DisplayType.Text,
                    isEditable: true,
                    showWhen: 'IsStockItem',
                    validationRules: []
                }];
	static inventoryitemcategory: IFieldMetadata[] = [{
                    name: 'Name',
                    type: DisplayType.Text,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }]
                },
{
                    name: 'Description',
                    type: DisplayType.TextArea,
                    isEditable: true,
                    showWhen: null,
                    validationRules: []
                }];
	static servicecategory: IFieldMetadata[] = [{
                    name: 'Title',
                    type: DisplayType.Text,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }]
                }];
	static staffmember: IFieldMetadata[] = [{
                    name: 'FirstName',
                    type: DisplayType.Text,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }]
                },
{
                    name: 'LastName',
                    type: DisplayType.Text,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Required }]
                },
{
                    name: 'PhoneNumber',
                    type: DisplayType.Text,
                    isEditable: true,
                    showWhen: null,
                    validationRules: [{ type: ValidationType.Phone }, { type: ValidationType.Required }]
                },
{
                    name: 'EmailAddress',
                    type: DisplayType.Text,
                    isEditable: true,
                    showWhen: null,
                    validationRules: []
                }];
}
