<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { PaymentFormData } from "@/store/payment";

@Component({
  name: "payu-checkout-form",
})
export default class PayUCheckoutForm extends Vue {
  @Prop({ required: true }) amount!: number;
  @Prop({ required: true }) description!: string;
  @Prop({ required: true }) customerEmail!: string;
  @Prop({ required: true }) customerFirstName!: string;
  @Prop({ required: true }) customerLastName!: string;
  @Prop({ required: true }) customerPhone!: string;
  @Prop({ required: true }) currency!: string;

  @Action("payment/initiatePayment") initiatePayment!: Function;
  @Getter("payment/formData") formData!: PaymentFormData | null;

  private isLoading: boolean = false;
  private error: string | null = null;

  async mounted() {
    await this.initializePayment();
  }

  private async initializePayment() {
    try {
      this.isLoading = true;
      this.error = null;

      await this.initiatePayment({
        amount: this.amount,
        currency: this.currency,
        description: this.description,
        customerEmail: this.customerEmail,
        customerFirstName: this.customerFirstName,
        customerLastName: this.customerLastName,
        customerPhone: this.customerPhone,
        successUrl: `${window.location.origin}/payment/success`,
        failureUrl: `${window.location.origin}/payment/failure`,
      });
    } catch (err) {
      this.error = "Failed to initialize payment. Please try again.";
      console.error("Payment initialization error:", err);
    } finally {
      this.isLoading = false;
    }
  }
}
</script>

<template>
  <div class="payu-checkout-form">
    <v-card v-if="!isLoading && !error && formData" flat>
      <v-card-title>Complete Your Payment</v-card-title>
      <v-card-text>
        <form :action="formData.action" method="post">
          <input
            v-for="(value, key) in formData.formFields"
            :key="key"
            type="hidden"
            :name="String(key)"
            :value="String(value)"
          />
          <v-btn color="primary" type="submit" block large class="mt-4">
            Pay {{ amount.toString() }} {{ currency }}
          </v-btn>
        </form>
      </v-card-text>
    </v-card>

    <v-card v-else-if="error" flat color="error" dark>
      <v-card-text>
        {{ error }}
        <v-btn text @click="initializePayment">Retry</v-btn>
      </v-card-text>
    </v-card>

    <v-card v-else flat>
      <v-card-text class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <div class="mt-2">Initializing payment...</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped>
.payu-checkout-form {
  max-width: 500px;
  margin: 0 auto;
}
</style>
