
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import signalR from "@microsoft/signalr";

export interface bookingHourState {
    bookingHourConnection: signalR.HubConnection,
}


export const state = {
    bookingHourConnection: null
};

export const getters: GetterTree<any, bookingHourState> = {
    bookingHourConnection:state => state.bookingHourConnection
};

export const mutations: MutationTree<bookingHourState> = {
    setBookingHourConnection(state : bookingHourState, payload: signalR.HubConnection) {
        state.bookingHourConnection = payload;
    },

};

export const bookingHourConnectionModule = {
    mutations,
    getters,
    state,
};
