import { GetterTree, MutationTree, ActionTree } from "vuex";
import axios from "axios";
import { APIURL2 } from "@/main";
import { IAvailableHoursRequest } from "@/store/appointments/appointmentTypes";

// Interface
export interface workingHoursState {
  workingDays: IWorkingDays | null;
  availableHours: IAvailableHour[];
  loadedAvailableHours: IAvailableHour[];
  allWorkingDays: IWorkingDays[];
  vacationDays: [];
  firstAvailableDay: Date | null;
  preloadedAvailableHours: [];
}

// Interface Model
export interface IworkingHours {
  dayOfWeek: string;
  hour: Number;
  minutes: Number;
}

export interface bookedWorkingHours {
  workingHoursByDay: string;
}

export interface IWorkingDays {
  dayOfWork: string;
  isWorking: boolean;
}

// Mutation enums
export const enum mutationStringWorkingHours {
  setAvailableHours = "setAvailableHours",
  loadAvailableHours = "loadAvailableHours",
  setFirstAvailableDay = "setFirstAvailableDay",
  setAllWorkingDays = "setAllWorkingDays",
  setWorkingDays = "setWorkingDays",
  setVacationDays = "setVacationDays",
}

export const enum actionStringWorkingHours {
  getWorkingHours = "getWorkingHours",
  GET_WORKING_HOURS_BY_DAY_AS_ANONYMOUS = "getWorkingHoursByDayAsAnonymous",
}

//TODO: Wtf?
export const state = {
  setWorkingDays: null,
  allWorkingDays: [],
  availableHours: [],
  loadedAvailableHours: [],
  vacationDays: [],
  firstAvailableDay: null,
  preloadedAvailableHours: [],
};

export interface IAvailableHour {
  date: Date;
  label: string;
  iso: string;
  hoursToBook: [];
}

export const getters: GetterTree<any, workingHoursState> = {
  availableHours: (state) => state.availableHours,
  allWorkingDays: (state) => state.allWorkingDays,
  workingDays: (state) => state.workingDays,
  vacationDays: (state) => state.vacationDays,
  firstAvailableDay: (state) => state.firstAvailableDay,
  loadedAvailableHours: (state) => state.loadedAvailableHours,
  preloadedAvailableHours: (state) => state.preloadedAvailableHours,
};

export const mutations: MutationTree<workingHoursState> = {
  setAvailableHours(state, payload: IAvailableHour[]) {
    state.availableHours = payload;
  },
  setAllWorkingDays(state, payload: any) {
    state.allWorkingDays = payload;
  },
  setWorkingDays(state, payload) {
    state.workingDays = payload;
  },
  setVacationDays(state, payload) {
    state.vacationDays = payload;
  },
  setFirstAvailableDay(state, payload) {
    state.firstAvailableDay = payload;
  },
  loadAvailableHour(state, payload) {
    state.loadedAvailableHours.push(payload);
  },
  resetAvailableHour(state, payload) {
    state.loadedAvailableHours = [];
  },

  loadPreloadedAvailableHours(state, payload) {
    state.preloadedAvailableHours = payload;
  },
  loadAvailableHours(state, payload) {
    let a = state.loadedAvailableHours;
    let b = payload;

    const mergedArray = [...a, ...b];
    // mergedArray have duplicates, lets remove the duplicates using Set
    let set = new Set();
    let unionArray = mergedArray.filter((item) => {
      if (!set.has(item.id)) {
        set.add(item.id);
        return true;
      }
      return false;
    }, set);

    state.loadedAvailableHours = unionArray;
  },
};

export const actions: ActionTree<workingHoursState, any> = {
  getAvailableHoursForMonth(
    { commit },
    payload: IAvailableHoursRequest
  ): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      axios
        .post(APIURL2 + "Appointment/availableHours/month", payload, {})
        .then((res) => {
          commit("loadAvailableHours", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  getAvailableHours(
    { commit },
    payload: IAvailableHoursRequest
  ): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      axios
        .post(APIURL2 + "Appointment/availableHours", payload, {})
        .then((res) => {
          commit(mutationStringWorkingHours.setAvailableHours, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  getFirstAvailableDay(
    { commit },
    payload: IAvailableHoursRequest
  ): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      axios
        .post(APIURL2 + "Appointment/firstAvailableHourDay/", payload, {})
        .then((res) => {
          commit(mutationStringWorkingHours.setFirstAvailableDay, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async getVacationDays({ commit }, payload): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          APIURL2 +
            "VacationDays/asAnonymous?userId=" +
            payload.userId +
            "&serviceIds=" +
            payload.serviceIds
        )
        .then((res) => {
          commit(mutationStringWorkingHours.setVacationDays, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async getAllWorkingDaysAsAnonymous({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          APIURL2 +
            "WorkingDays/all/?userId=" +
            payload.userId +
            "&serviceIds=" +
            payload.serviceIds
        )
        .then((res) => {
          commit(mutationStringWorkingHours.setAllWorkingDays, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export const workingHours = {
  actions,
  mutations,
  getters,
  state,
};
