import { GetterTree, MutationTree, ActionTree } from "vuex";
import axios from "axios";
import { APIURL2 } from "@/main";
import { IStaffMemberBookingDTO } from "@shared/types";

// Interface
export interface staffState {
  selectedStaffMember: IStaffMemberBookingDTO | null;
  staffMemberCount: number;
}

// Mutation enums
export const enum mutationStringStaff {
  SET_SELECTED_STAFF = "setSelectedStaff",
  SET_STAFF_COUNT = "setStaffCount",
}

export const enum actionStringStaff {
  GET_STAFF_MEMBERS = "getStaffMembers",
  GET_STAFF_COUNT = "getStaffCount",
}

export const state: staffState = {
  selectedStaffMember: null,
  staffMemberCount: 0,
};

export const getters: GetterTree<staffState, any> = {
  selectedStaffMember: (state) => state.selectedStaffMember,
  staffMemberCount: (state) => state.staffMemberCount,
};

export const mutations: MutationTree<staffState> = {
  [mutationStringStaff.SET_SELECTED_STAFF](
    state,
    payload: IStaffMemberBookingDTO | null
  ) {
    state.selectedStaffMember = payload;
  },
  [mutationStringStaff.SET_STAFF_COUNT](state, payload: number) {
    state.staffMemberCount = payload;
  },
};

export const actions: ActionTree<staffState, any> = {
  [actionStringStaff.GET_STAFF_COUNT](
    { commit },
    adminId: string
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURL2 + "StaffMember/count/" + adminId)
        .then((res) => {
          commit(mutationStringStaff.SET_STAFF_COUNT, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  [actionStringStaff.GET_STAFF_MEMBERS](
    { commit },
    { adminId, serviceBookings }: { adminId: string; serviceBookings: any[] }
  ): Promise<IStaffMemberBookingDTO[]> {
    return new Promise((resolve, reject) => {
      axios
        .post(APIURL2 + "StaffMember/asAnonymous/" + adminId, {
          serviceBookings,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};

export const staff = {
  state,
  getters,
  mutations,
  actions,
};
