import {APIURL2} from '@/main';
import axios from "axios";

export async function getRequest(request: string) : Promise<any>{


    return new Promise((resolve, reject) => {
        axios.get(APIURL2 + request,
            {}).then((res) => {
            return resolve(res.data);
        }).catch((err) => {
            console.log(err);
            return reject(err);
        });
    });
}

export async function deleteRequest(request: string) : Promise<any>{


    return new Promise((resolve, reject) => {
        axios.delete(APIURL2 + request,
            {}).then((res) => {
            return resolve(res.data);
        }).catch((err) => {
            console.log(err);
            return reject(err);
        });
    });
}

export async function postRequest(action: string,body: {}, credentials: boolean = false) : Promise<any>{

    return new Promise((resolve, reject) => {
        axios.post(APIURL2 + action ,
            body, {}).then((res) => {
            return resolve(res.data);
        }).catch((err) => {
            console.log(err);
            return reject(err);
        });
    });
}
export async function putRequest(action: string,body: {}, credentials: boolean = false) : Promise<any>{

    return new Promise((resolve, reject) => {
        axios.put(APIURL2 + action ,
            body, {}).then((res) => {
            return resolve(res.data);
        }).catch((err) => {
            console.log(err);
            return reject(err);
        });
    });
}

