<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {Getter, Mutation, Action} from "vuex-class";
    import router from '@/router';
    import App from "@/App.vue";
    import firebase from 'firebase'
    import { IUser } from '../../store/user';
    import { atcb_action, atcb_init } from 'add-to-calendar-button';

    @Component({
        name:'receipt',
        components: { App},
    })
    export default class Receipt extends Vue {

      @Action getUserAsAnonymous: (userId: string) => Promise<IUser>;
      @Mutation setBookingStep;
      @Getter public userToBook;
      @Prop() public serviceBookingDetails;

      mounted() {
        atcb_init();
      }

      public goBackToBookingPage(): void {
        let userId: string = this.$route.params.userId;
        let userPageRoute = "/booking/" + userId;
        router.replace(userPageRoute)
      }

      public enterBooking() {
        this.setBookingStep(1);
        this.$router.push("/" + this.userToBook.bookingLink);
      }

      public enterMyPage() {
        this.setBookingStep(1);
        this.$router.push("/" + this.userToBook.bookingLink + "/min-side");
      }


    }


</script>
<template>
    <div>

    </div>
</template>


<style lang="scss" scoped>
    button{
        padding:5px 30px 5px 41px !important;

    }
    body{
        background:white !important;
    }
    h1{
        font-size: 3em;
        padding-bottom: 15px;
        // color:$purple;
    }



    .login-wrapper{
        width:50%;
        margin:25px 25%;
        float:left;
    }
    .left-section, .right-section{
        width:30%;
        float:left;
        height:100%;
         p{
             margin-top: 10px;
            color:gray;
        }
    }
    .right-section{
        width:70% !important;
    }


    .login-content{
        padding: 40px 0 0 65px;
        margin-top: 40px;
        img{
            height: 200px;
            margin-top: -109px;
            margin-right: 49px;
            float: right;
        }
        h2{
            color:black;
        }
       
    }


    /** MEDIA QUERIES **/
    @media only screen and (max-width: 1000px){
        .login-wrapper{
            width: 100%;
            margin: 0px 0;
            padding: 80px;
        }
    }
    @media only screen and (max-width: 700px){
        .login-wrapper{
            text-align: center;
            button{
                display: block;
                margin: 0 auto;
                float:initial;
            }
            h1{
                font-size: 2.2em;
                padding-bottom: 15px;
            }
        }
        .left-section{
            display: none;
        }
        .right-section{
            width:100% !important;
        }
    
        .point{
            width:100% !important;
        }
    }
    
</style>
