import "babel-polyfill";
import Vue from "vue";
import VueI18n from "vue-i18n";
import { en } from "./locales/en";
import { es } from "./locales/es";
import { no } from "./locales/no";
import { TranslationSchema } from "./locales/translation.interface";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import * as Sentry from "@sentry/vue";

Vue.use(VueI18n);

const savedLocale = localStorage.getItem("locale");

let defaultLocale = "no";
if (savedLocale) {
  defaultLocale = savedLocale;
}

// Export the schema using English translations as the base
export const translations: TranslationSchema = en;

// Extend Vue's type definitions to include the schema
declare module "vue/types/vue" {
  interface Vue {
    $ts: TranslationSchema;
  }
}

// Create a proxy for the translations object
function createTranslationsProxy(target: any, path: string = ""): any {
  return new Proxy(target, {
    get(target, prop) {
      if (prop in target) {
        const value = target[prop];
        const newPath = path ? `${path}.${String(prop)}` : String(prop);
        if (typeof value === "object" && value !== null) {
          // Return a new proxy for nested objects with updated path
          return createTranslationsProxy(value, newPath);
        }
        return newPath; // Return the full path of the property
      }
      return undefined;
    },
  });
}

const translationsProxy = createTranslationsProxy(translations);

// Install the proxy as a global property
Vue.prototype.$ts = translationsProxy;

const messages = { en, es, no } as any;

const i18n = new VueI18n({
  locale: defaultLocale,
  messages,
});

export { i18n };

import App from "./App.vue";

import router from "./router";
import { store } from "./store/index";
import vuetify from "./plugins/vuetify";
import VueGtag from "vue-gtag";
Vue.config.productionTip = false;

export let APIURL2: string;
export let HUBURL: string;
export let USE_DEV_SITE: boolean;
export let IS_DEVELOPING: boolean;

IS_DEVELOPING = window.location.hostname === "localhost";
USE_DEV_SITE = false;

// Set API URLs based on environment
APIURL2 =
  process.env.VUE_APP_API_URL ||
  (process.env.NODE_ENV === "production"
    ? "https://timebestillerwebapp.azurewebsites.net/api/"
    : "https://localhost:5001/api/");

HUBURL = process.env.VUE_APP_HUB_URL || APIURL2.replace("/api/", "/hubs/");

Vue.use(VueGtag, {
  config: { id: "G-11THJNCD93" },
});

// import wb from "./service-worker";
// Vue.prototype.$workbox = wb;

console.log("Node ENV: ", process.env.NODE_ENV);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://15078f0adca84c6dbc28eabc305e39f3@o306737.ingest.us.sentry.io/1765691",
    environment: process.env.VUE_APP_ENVIRONMENT || "development",
    release: process.env.VUE_APP_SENTRY_RELEASE,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    trackComponents: true,
  });

  Sentry.setTag("app", "booking");
} else {
  Vue.component("ErrorBoundary", {
    data: () => ({ error: null }),
    errorCaptured(err, vm, info) {
      this.error = `${err.stack}\n\nfound in ${info} of component`;
      return false;
    },
    render(h) {
      if (this.error) {
        return h("pre", { style: { color: "red" } }, this.error);
      }
      // ignoring edge cases for the sake of demonstration
      return this.$slots.default[0];
    },
  });
}

new Vue({
  i18n,
  router,
  store,
  vuetify: vuetify,
  render: (h) => h(App),
  async created() {
    //TODO: Investigate if we're even using signalR anymroe
    // let bookingHourHubURL = HUBURL + "bookingHour";
    // const connection: signalR.HubConnection = new signalR.HubConnectionBuilder()
    //   .withUrl(bookingHourHubURL, {
    //     skipNegotiation: true,
    //     transport: signalR.HttpTransportType.WebSockets,
    //   })
    //   .configureLogging(signalR.LogLevel.Debug)
    //   .build();
    // connection.start().then((x) => {
    //   store.commit("setBookingHourConnection", connection);
    // });
  },
}).$mount("#app");
