
export enum DialogType{
    Message,
    Choice,
YesNo
}

export enum DialogMutations{
    AddToDialogQueue = "addToDialogQueue",
    SetFulllScreenComponent = "setCurrentFullScreenComponent",
}
export enum DialogActions{
    AddToDialogQueue = "addToDialogQueue"
}

interface IDialogItemList extends Array<IDialog>{}
type Action = () => void;
export interface IDialog{
    header?: string;
    icon?: string;
    text: string;
    actionButtonDelay?: number;
    enabled?: boolean;
    action?: Action|null;
    cancelAction?: Action|null;
    type?: DialogType;
    created?: number;
    setBusyToFalseAfter?: boolean
}

interface IDialogState{
    dialogQueue: IDialogItemList,
    fullscreenDialog: boolean,
    currentFullScreenComponent: string
}

const state : IDialogState =  {
    fullscreenDialog: false,
    dialogQueue: [],
    currentFullScreenComponent: "",
};


const mutations =  {
    setFullScreenDialog(state : IDialogState, payload : boolean){
        state.fullscreenDialog = payload;
    },

    setCurrentFullScreenComponent(state : IDialogState, payload : string){
        state.currentFullScreenComponent = payload;
    },
    addToDialogQueue(state : IDialogState, payload : IDialog){


        let lastDialog = state.dialogQueue[state.dialogQueue.length - 1];
        if(lastDialog != null){
            let diff = Date.now() - lastDialog.created;
            //Don't create a duplicate message which is recent as well //TODO: This should be handled by the caller?
            if(lastDialog.text == payload.text && diff < 500){
                return;
            }
        }

        console.log(payload);

        let header = "Info";
        let icon = "info";
        let action = null;
        let cancelAction = null;
        let text = "No text";
        let type = DialogType.Message;
        let actionButtonDelay = 0;
        let setBusyToFalseAfter = true;

        if(payload.header != null) header = payload.header;
        if(payload.icon != null) icon = payload.icon;
        if(payload.cancelAction != null) cancelAction = payload.cancelAction;
        if(payload.action != null) action = payload.action;
        if(payload.text != null) text = payload.text;
        if(payload.type != null) type = payload.type;
        if(payload.setBusyToFalseAfter != null) setBusyToFalseAfter = payload.setBusyToFalseAfter;
        if(payload.actionButtonDelay != null) actionButtonDelay = payload.actionButtonDelay;

        let newDialog : IDialog = {
            text: text,
            type: type,
            action: action,
            cancelAction: cancelAction,
            icon: icon,
            header: header,
            enabled: false,
            actionButtonDelay: actionButtonDelay,
            created: Date.now(),
            setBusyToFalseAfter: setBusyToFalseAfter
        };
        state.dialogQueue.push(newDialog);

        setTimeout(() => {
            newDialog.enabled = true;
        }, 100);

    },
    closeNormalDialog(state, payload){
        // latestDialog.enabled = false;

        let latestDialog = state.dialogQueue.find(function(e) {
            return e.enabled;
        });

        if(payload == true && latestDialog.action !== null && typeof latestDialog.action == "function"){
            latestDialog.action();
            if(latestDialog.setBusyToFalseAfter != null){
                if(latestDialog.setBusyToFalseAfter == true){
                }
            } else {
            }
        } else {
        }
        if(payload == false && latestDialog.cancelAction !== null && typeof latestDialog.cancelAction == "function"){
            latestDialog.cancelAction();
        }

        latestDialog.enabled = false;

        // setTimeout(() => {
        //   let index = state.dialogQueue.indexOf(latestDialog);
        //   state.dialogQueue.splice(index, 1);
        // }, 10000)
    }
};
const actions = {
    addToDialogQueue({commit, dispatch}, payload : IDialog){
        commit(DialogMutations.AddToDialogQueue, payload);
    }
};
const getters = {
    dialogQueue : (state) => {
        return state.dialogQueue;
    },
    fullscreenDialog: (state) =>{
        return state.fullscreenDialog;
    },
    currentFullScreenComponent: (state) =>{
        return state.currentFullScreenComponent;
    },
};

export const dialog = {
    state,
    mutations,
    actions,
    getters
};
