import { render, staticRenderFns } from "./CustomerAccount.vue?vue&type=template&id=8f9ae22e&scoped=true"
import script from "./CustomerAccount.vue?vue&type=script&lang=ts"
export * from "./CustomerAccount.vue?vue&type=script&lang=ts"
import style0 from "./CustomerAccount.vue?vue&type=style&index=0&id=8f9ae22e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f9ae22e",
  null
  
)

export default component.exports