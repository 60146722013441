<template>
  <main-app></main-app>
</template>

<script lang="ts">
import Vue from "vue";

import MainApp from "@/MainApp.vue";

export default Vue.extend({
  name: "App",

  components: {
    MainApp,
  },

  data: () => ({
    //
  }),
});
</script>

<style lang="scss">
.v-picker__body {
  border-radius: 6px;
}
.v-bottom-sheet.v-dialog.v-bottom-sheet--inset {
  max-width: none;
  align-self: flex-end;
  border-radius: 0;
  flex: 0 1 auto;
  margin: 0;
  overflow: visible;
}
@media only screen and (max-width: 599px) {
  .v-bottom-sheet.v-dialog.v-bottom-sheet--inset {
    max-width: none;
  }
}

.application {
  font-family: inherit;
  line-height: inherit;
}
body {
  font-size: 14px;
}
html {
  scroll-behavior: smooth;
}
p {
  margin-bottom: 0;
}
.my-page-button {
  margin-right: 20px;
}
.header-logout {
  margin-left: -2rem;
  padding: 12px 20px !important;

  background-color: #fff !important;
  border: 2px solid #5c6bc0;
  border-radius: 5rem;
  color: #5c6bc0 !important;
  cursor: pointer;
  &:hover {
    background: #cccccc !important;
  }
  &--mobile {
    margin-left: 0;
  }
}
.v-content {
  @media only screen and (max-width: 600px) {
    padding: 0 !important;
  }
}
</style>
